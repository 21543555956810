<template>
    <div class="min-h-screen h-fit bg-gray-100 py-8">
      <div class="max-w-3xl mx-auto px-4">
        <h1 class="text-4xl font-bold text-center mb-8">Terms & Conditions</h1>
        <p class="text-gray-700 mb-4">
          Last updated on May 1st 2024
        </p>
        <div class="bg-white rounded-lg shadow-md p-6">
          <p class="text-gray-700 mb-4">
            For the purpose of these Terms and Conditions, the term "we", "us", "our" used anywhere on this page shall mean CSP Photo & ManualAadhar Print, whose registered/operational office is Bhaluaha Kanhauli, Sonbarsa, Sitamarhi, Bihar 843317. "You", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.
          </p>
  
          <p class="text-gray-700 mb-4">
            Your use of the website and/or purchase from us are governed by the following Terms and Conditions:
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">1. Changes to Content</h2>
          <p class="text-gray-700 mb-4">
            The content of the pages of this website is subject to change without notice.
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">2. Accuracy of Information</h2>
          <p class="text-gray-700 mb-4">
            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">3. Use of Information</h2>
          <p class="text-gray-700 mb-4">
            Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through our website and/or product pages meet your specific requirements.
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">4. Intellectual Property</h2>
          <p class="text-gray-700 mb-4">
            Our website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">5. Trademarks</h2>
          <p class="text-gray-700 mb-4">
            All trademarks reproduced on our website which are not the property of, or licensed to, the operator are acknowledged on the website.
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">6. Unauthorized Use</h2>
          <p class="text-gray-700 mb-4">
            Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">7. External Links</h2>
          <p class="text-gray-700 mb-4">
            From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">8. Linking to Our Website</h2>
          <p class="text-gray-700 mb-4">
            You may not create a link to our website from another website or document without CSP Photo & ManualAadhar Print’s prior written consent.
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">9. Governing Law</h2>
          <p class="text-gray-700 mb-4">
            Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India.
          </p>
  
          <h2 class="text-2xl font-semibold mb-4">10. Liability</h2>
          <p class="text-gray-700 mb-4">
            We shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any transaction, on account of the cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.
          </p>
  
          <p class="text-gray-700 mb-4">
            Disclaimer: The above content is created at Rajnish Kumar's sole discretion. CSP Photo & ManualAadhar Print shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.
          </p>
  
          <div class="mt-6 text-center">
            <button @click="acceptTerms" class="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-full">
              I Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      acceptTerms() {
        // Logic to handle acceptance of terms
        this.$emit('accepted');
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any scoped styles here */
  </style>
  