<template>
    <div class="min-h-screen h-fit bg-gray-100 py-8 mt-20">
      <div class="max-w-3xl mx-auto px-4">
        <h1 class="text-4xl font-bold text-center mb-8">Contact Us</h1>
        <p class="text-gray-700 mb-4">
          Last updated on May 1st 2024
        </p>
        <div class="bg-white rounded-lg shadow-md p-6">
          <p class="text-gray-700 mb-4">
            You may contact us using the information below:
          </p>
  
          <div class="mb-4">
            <h2 class="text-2xl font-semibold mb-2">Merchant Legal Entity Name:</h2>
            <p class="text-gray-700">CSP Photo & ManualAadhar Print</p>
          </div>
  
          <div class="mb-4">
            <h2 class="text-2xl font-semibold mb-2">Registered Address:</h2>
            <p class="text-gray-700">
              Bhaluaha Kanhauli, Sonbarsa, Sitamarhi, Bihar 843317
            </p>
          </div>
  
          <div class="mb-4">
            <h2 class="text-2xl font-semibold mb-2">Operational Address:</h2>
            <p class="text-gray-700">
              Bhaluaha Kanhauli, Sonbarsa, Sitamarhi, Bihar 843317
            </p>
          </div>
  
          <div class="mb-4">
            <h2 class="text-2xl font-semibold mb-2">Telephone No:</h2>
            <p class="text-gray-700">7488150245</p>
          </div>
  
          <div class="mb-4">
            <h2 class="text-2xl font-semibold mb-2">E-Mail ID:</h2>
            <p class="text-gray-700">kumarrajnish.stm@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    // No data or methods are necessary for this static component
  };
  </script>
  
  <style scoped>
  /* Add any scoped styles here */
  </style>
  