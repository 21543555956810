<template>
    <div class="min-h-screen h-fit mt-16 bg-gray-100 py-8">
        <div class="max-w-5xl mx-auto px-4">
            <h1 class="text-4xl font-bold text-center mb-8">Prepaid Plans For DL Print Only</h1>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
                <div v-for="(item, index) in items" :key="index" class="bg-white rounded-lg shadow-md overflow-hidden">
                    <div class="px-6 py-4">
                        <div class="font-bold text-xl mb-2">
                           
                            <img  src="../assets/diamond.png" alt="Diamond Icon"
                                class="inline-block w-6 h-6 ml-2">
                            {{ item.name }}
                        </div>
                        <p class="text-gray-700 text-lg">Rs {{ item.price }}</p>
                    </div>
                    <div class="px-6 py-4">
                        <button @click="showPrefillDialog(item)" :disabled="!userId"
                            class="bg-primary hover:bg-primary-dark cursor-pointer text-white font-bold py-2 px-4 rounded-full w-full">
                            Pay Now
                        </button>
                    </div>
                </div>
            </div>
            <!-- Dialog Code -->
            <div v-if="showDialog" class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div
                        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="sm:flex sm:items-start">
                                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                        Checkout
                                    </h3>
                                    <div class="mt-4">
                                        <div class="border-t border-gray-200">
                                            <div class="flex justify-between py-2">
                                                <span class="font-medium">Plan:</span>
                                                <span>{{ selectedPlan.name }}</span>
                                            </div>
                                            <div class="flex justify-between py-2">
                                                <span class="font-medium">Price:</span>
                                                <span>Rs {{ selectedPlan.price }}</span>
                                            </div>
                                            <div class="border-t border-gray-200 mt-4">
                                                <h4 class="text-lg font-medium text-gray-900">Enter Your Information
                                                </h4>
                                                <div class="mt-2">
                                                    <input v-model="prefillData.name" type="text" placeholder="Name"
                                                        class="w-full py-2 my-2 border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50 mb-2">
                                                    <input v-model="prefillData.contact" type="tel"
                                                        placeholder="Contact Number"
                                                        class="w-full py-2 my-2 border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50">
                                                    <input type="text" placeholder="Address Along with pincode"
                                                        class="w-full py-2 my-2 border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button @click="initiatePayment(selectedPlan.price)" type="button"
                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm">
                                Place order
                            </button>
                            <button @click="closeDialog" type="button"
                                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-8 text-center text-gray-700">
                <h1 class="font-bold">Important: This plan does not apply to the CSP Photo Print application.</h1>
                <br>
                <p class="mb-4">
                    CSP Photo Print now offers the ability to purchase Coins, a virtual currency within the app, to
                    print
                    documents. By purchasing Coins, you agree to the terms and conditions outlined in this document,
                    including the
                    non-refundable nature of Coin purchases, the limited use of Coins for printing services within the
                    app, and
                    the forfeiture of any unused Coins in the event of app uninstallation or termination of the Coin
                    service by
                    CSP Photo Print. The Premium plan offers a completely ad-free experience. Please read the full terms
                    and
                    conditions for additional information.
                </p>
               
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    data() {
        return {
            items: [
                { name: '7 Days Unlimited', price: 89 },
                { name: '6 Months Unlimited', price: 599 },
                { name: '1 Year Unlimited', price: 899 },
            ],
            showDialog: false,
            selectedPlan: null,
            prefillData: {
                name: '',
                contact: ''
            },
            phoneError: '',
            userId: ''
        };
    },

  
    mounted() {
        this.userId = this.$route.query.userId;
        this.prefillData.name = this.$route.query.name || '';
        localStorage.setItem("userIdDL",this.userId)
     
    },



    methods: {
  

        showPrefillDialog(plan) {
            if (!this.userId) {
                alert('UID is empty. Please reload the page.');
                return;
            }
            this.selectedPlan = plan;
            this.showDialog = true;
        },

        validatePhoneNumber() {
            const phoneRegex = /^[0-9]{10}$/;
            if (!phoneRegex.test(this.prefillData.contact)) {
                this.phoneError = 'Please enter a valid 10-digit phone number.';
            } else {
                this.phoneError = '';
            }
        },

        generateRandomVerificationId() {
            return 'RV' + Math.random().toString(36).substr(2, 9) + Date.now();
        },


        async initiatePayment(amount) {
            if (!this.prefillData.name || !this.prefillData.contact) {
                alert('Please enter your  contact number and address.');
                return;
            }

            this.validatePhoneNumber();

            if (this.phoneError) {
                alert('Invalid phone number. कृपया सही नंबर दर्ज करें।');
                return;
            }

            localStorage.setItem("amount", amount);
            const randomVerificationId = this.generateRandomVerificationId();
            const uidFirst3later = this.userId.slice(0, 3);




            const data = {
                name: this.prefillData.name,
                amount: amount,
                number: this.prefillData.contact,
                MID: 'MID' + Date.now(),
                transactionId: uidFirst3later + Date.now(),
                randomVerificationId: randomVerificationId,

            }
            localStorage.setItem("transactionId", data.transactionId);
            localStorage.setItem("randomVerificationId", data.randomVerificationId);

            try {
                await axios.post(`${window.location.origin}/order`, data).then(res => { // production  url
                    if (res.data.success === true) {
                        window.location.href = res.data.data.instrumentResponse.redirectInfo.url;
                    }
                }).catch(err => {
                    console.log(err);
                });

            } catch (err) {
                console.log(err);
            }

            this.showDialog = false;
        },

        closeDialog() {
            this.showDialog = false;
        },
    },
};
</script>

<style scoped></style>