<template>
  <div class="container mx-auto">
    <div class="my-4">
      <h1 class="text-3xl font-bold mb-4">Our Services</h1>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <ServiceCard
          v-for="service in services"
          :key="service.id"
          :name="service.name"
          :description="service.description"
          :thumbnail="service.thumbnail"
          :id="service.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCard from './ServiceCard.vue'

export default {
  name: 'DashBoard',
  components: {
    ServiceCard,
  },
  data() {
    return {
      services: [
        {
          id: "1",
          name: 'Passport Size Photo print',
          description: 'Get your passport size photo printed in high quality.',
          thumbnail: require("../assets/personicon.png"),
        },
        {
          id: "2",
          name: 'Aadhaar Card Print',
          description: 'Get your Aadhaar card printed on PVC plastic.',
          thumbnail: require("../assets/adharcardicon.png"),
        },
        {
          id: "3",
          name: 'Passbook Print',
          description: 'Get your bank passbook printed with your latest transactions.',
          thumbnail: require("../assets/passbookicon.png"),
        },
        {
          id: "4",
          name: 'Manual Aadhaar Print',
          description: 'Get your Aadhaar card printed on paper.',
          thumbnail: require("../assets/aadhaaricon.png"),
        },
        {
          id: "5",
          name: 'Manual Voter Card print',
          description: 'Get your voter ID card printed on paper.',
          thumbnail: require("../assets/voter_icon.png"),
        },
        {
          id: "6",
          name: 'Manual Pan Card print',
          description: 'Get your PAN card printed on paper.',
          thumbnail: require("../assets/pan_logo.png"),
        },
      ],
    }
  },
}
</script>
