<template>
  <header class="bg-secondary shadow" style="position: fixed; top: 0; width: 100%; z-index: 999">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center h-16">
        <div class="flex-shrink-0 flex items-center " v-if="!isLoading">
          <img class="h-8 w-auto" v-if="isPremium" src="../assets/diamond.png" alt="CSP Photo & Aadhaar Print" />
          <img class="h-8 w-auto" v-else src="../assets/coins.png" alt="CSP Photo & Aadhaar Print" />

          <div class="flex items-start justify-start text-start">

            <h1 class="ml-3 text-sm leading-6 text-white" v-if="isPremium">
              Access Premium Perc

            </h1>
            <h1 class="ml-3 text-sm leading-6 text-white" v-else>
              Coins (as of now) {{ coins }}
            </h1>
           
          </div>


        </div>
        <div
          class="flex-shrink-0 flex items-center animate-spin rounded-full border-t-2 border-b-2  w-8 h-8  border-white"
          v-else></div>

        <div class="flex items-center">
          <div class="hidden sm:block">
            <nav class="flex">
              <router-link to="/" class="px-3 py-2 text-white font-medium hover:font-bold">Home</router-link>
              <router-link to="/service-details"
                class="px-3 py-2 text-white font-medium hover:font-bold">Services</router-link>
                <router-link to="/BuyCoins"
                class="px-3 py-2 text-white font-medium hover:font-bold">Buy Coins</router-link>
                 <router-link to="/DLBuyCoins"
                class="px-3 py-2 text-white font-medium hover:font-bold">Other Plans</router-link>
              <router-link to="/About-us" class="px-3 py-2 text-white font-medium hover:font-bold">About
                Us</router-link>
     
              <router-link  to="/Profile" v-if="isAuthenticated"
                class="px-3 py-2 text-white font-medium hover:font-bold">Profile</router-link>
            </nav>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <button @click="isMenuOpen = !isMenuOpen" type="button"
              class="bg-gray-200 p-2 rounded-md text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <!-- Icon when menu is closed. -->
              <!-- Menu open: "hidden", Menu closed: "block" -->
              <svg :class="{ hidden: isMenuOpen, block: !isMenuOpen }" class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <!-- Icon when menu is open. -->
              <!-- Menu open: "block", Menu closed: "hidden" -->
              <svg :class="{ block: isMenuOpen, hidden: !isMenuOpen }" class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div :class="{ block: isMenuOpen, hidden: !isMenuOpen }" class="sm:hidden" id="mobile-menu">
      <nav class="px-2 pt-2 pb-3 space-y-1">
        <router-link to="/" class="block px-3 py-2 text-base font-medium text-white hover:font-bold" @click="closeMenu">Home</router-link>
        <router-link to="/service-details" class="block px-3 py-2 text-base font-medium text-white hover:font-bold" @click="closeMenu">Services</router-link>
        <router-link to="/BuyCoins" class="block px-3 py-2 text-base font-medium text-white hover:font-bold" @click="closeMenu">Buy Coins or Upgrade to Premium</router-link>
        <router-link to="/DLBuyCoins"
        class="px-3 py-2 text-white font-medium hover:font-bold">Other Plans</router-link>
        <router-link to="/About-us" class="block px-3 py-2 text-base font-medium text-white hover:font-bold" @click="closeMenu">About Us</router-link>
        <router-link  to="/Profile" v-if="isAuthenticated"
        class="block px-3 py-2 text-base font-medium text-white hover:font-bold" @click="closeMenu">Profile</router-link>
      </nav>
    </div>
  </header>
</template>
<script>

import { auth } from './firebase'; // Assuming you have a firebase.js file where you initialize Firebase
import router from '@/routes'; // Assuming your router file is named 'routes.js'
import { mapState } from 'vuex';
import store from './store';


export default {
  computed: {
    ...mapState(['coins', 'isPremium', 'isAuthenticated']),
  },

  methods: {
    closeMenu() {
      this.isMenuOpen = false;
    },

    logout() {

      auth.signOut().then(() => {
        store.dispatch('setIsAuthenticated', false);
        router.push({ name: 'Login' });

      }).catch(error => {
        // Handle any errors that occur during logout
        console.error('Logout error:', error);
      });
    },

  },
  data() {
    return {
      isMenuOpen: false,
      isLoading: false,

    };
  },



};
</script>