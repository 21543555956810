<template>
    <div class="min-h-screen h-fit bg-gray-100 py-8 mt-20">
      <div class="max-w-3xl mx-auto px-4">
        <h1 class="text-4xl font-bold text-center mb-8">Shipping & Delivery Policy</h1>
        <p class="text-gray-700 mb-4">
          Last updated on May 1st 2024
        </p>
        <div class="bg-white rounded-lg shadow-md p-6">
          <p class="text-gray-700 mb-4">
            Shipping is not applicable for business.
          </p>
          <p class="text-gray-700">
            Disclaimer: The above content is created at Rajnish Kumar's sole discretion. CSP Photo & ManualAadhar Print shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    // No data or methods are necessary for this static component
  };
  </script>
  
  <style scoped>
  /* Add any scoped styles here */
  </style>
  