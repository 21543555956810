<template>
    <div class="flex flex-col items-center p-6 bg-gray-100 rounded-md shadow-md">
      <h2 class="text-2xl font-semibold text-gray-800 mb-4">Latest Update Available</h2>
      <p class="text-gray-600 mb-6 text-center">
        We've made improvements and fixed bugs in the latest version of our app. Please update to the latest version for the best experience.
      </p>
      <button @click="downloadUpdate" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded">
        Download Latest Version
      </button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        updateLink: "https://your-download-link.com/app-latest-version", // Replace with your actual download link
      };
    },
    methods: {
      downloadUpdate() {
        window.open(this.updateLink, "_blank");
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  