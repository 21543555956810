<template>
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="pt-12 pb-16 md:pt-20 md:pb-24 md:flex md:items-center">


        
      
        <div class="md:w-1/2 mt-8 md:mt-0">
          <h1 class="text-5xl md:text-7xl font-bold tracking-tight text-gray-900 text-center md:text-left">Welcome to CSP Photo & Aadhaar Print</h1>
          <h1 class="mt-5 md:text-xl text-gray-600 text-center md:text-left">if you are facing any issue please kindly Contact us <b>7488150245</b></h1>
          
          <p class="mt-5 md:text-xl text-gray-600 text-center md:text-left">We offer a range of printing services to meet your needs. Whether you need passport size photos, Aadhaar card prints, or manual printing services, we've got you covered.</p>
          <div class="mt-10 md:mt-16 flex items-center justify-center md:justify-start">
            <a href="https://drive.usercontent.google.com/download?id=1UJa_j2dIkAUM--q65YyuOBVopviFkRbM&export=download&authuser=0" target="_blank" rel="noopener noreferrer" class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded">Install Mobile App</a>
            <router-link to="/BuyCoins" class="bg-primary hover:bg-secondary text-white font-bold py-2  px-4 mx-2 rounded">Upgrade To Premium</router-link>
          </div>
       
        </div>
        <div class="md:w-1/2 mt-3">
          <img class="w-full h-auto md:max-w-md mx-auto md:mx-0 md:ml-auto" src="../assets/printer.svg" alt="CSP Photo & Aadhaar Print Logo">
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>

export default {
  methods:{
      redirectToOrderPurchase() {
     
        // this.$router.push({ name: "Practice" });
      
  }
}
    
  
  }
</script>



 