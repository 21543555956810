<template>
  <div class="mt-10 flex justify-center items-center h-screen">
    <div
      class="flex items-start max-w-fit shadow-sm h-fit border-2 border-primary"
    >
      <div class="h-fit w-1/2 my-2 mx-2 py-2 px-6">
        <input
          type="file"
          @change="handlePassBookImageUpload"
          accept="image/*"
        />
        <div class="mt-4">
          <img
            :src="passbookImageDestination"
            alt="Back Image Preview"
            class="max-w-xs max-h-xs aadhar-card-image"
          />
        </div>
      </div>

      <div class="w-full">
        <div class="h-fit w-full mb-2 mx-2 py-2 px-6">
          <h2 class="text-xl font-bold mb-2 w-full">PDF Preview</h2>
          <div class="bg-white border border-gray-300 p-4 rounded-md">
            <object
              :data="pdfDataUrl"
              type="application/pdf"
              width="100%"
              height="560px"
            >
              <p>Preview is not available.</p>
            </object>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog box -->
    <modal v-if="isDialogOpen" @close="closeDialog">
      <div class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-8 border-2 border-primary">
          <div class="flex justify-start">
            <div class="mx-4" v-if="passBookImagePreview">
              <img
                :src="passBookImagePreview"
                alt="Image"
                class="DestImageWidth"
                ref="image"
                @load="EditImage()"
              />
            </div>

            <div v-if="passBookImagePreview">
              <img
                :src="passbookImageDestination"
                alt="Image"
                class="DestImageWidth"
              />
            </div>
          </div>
          <div class="justify-center mt-4">
            <button
              v-if="passBookImagePreview"
              @click="saveImage()"
              class="bg-primary text-white font-bold py-2 px-4 rounded"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import Cropper from "cropperjs";

export default {
  data() {
    return {
      pdfDataUrl: "",
      pdfBlob: null,
      pdfDownloadUrl: "",
      isDialogOpen: false,
      dialogImageUrl: "",
      passBookImagePreview: "",

      passbookImageDestination: require("../assets/defaultimage.png"),
    };
  },
  methods: {
    openDialog(e) {
      // Set the initial image URL for display in the dialog
      this.dialogImageUrl = e;

      // Open the dialog
      this.isDialogOpen = true;
    },
    saveImage() {
      // Save the edited image data
      // Assign dialogImageUrl to imageData
      this.imageData = this.dialogImageUrl;

      // Close the dialog
      this.isDialogOpen = false;

      this.generatePDF();

      this.passBookImagePreview = null;
    },

    EditImage() {
      this.image = this.$refs.image;
      this.cropper = new Cropper(this.image, {
        zoomable: true,
        scalable: false,

        crop: () => {
          const canvas = this.cropper.getCroppedCanvas();
          if (canvas.width === 0 || canvas.height === 0) {
            console.error("Invalid canvas dimensions");
            return;
          }
          // Create a new canvas with border
          const borderedCanvas = document.createElement("canvas");
          const ctx = borderedCanvas.getContext("2d");

          // Set border size and color
          const borderSize = 1; // in pixels
          const borderColor = "black";

          // Calculate new canvas size with border
          borderedCanvas.width = canvas.width + borderSize * 2;
          borderedCanvas.height = canvas.height + borderSize * 2;

          // Draw border
          ctx.fillStyle = borderColor;
          ctx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);

          // Draw cropped image
          ctx.drawImage(canvas, borderSize, borderSize);

          this.passbookImageDestination =
            borderedCanvas.toDataURL("image/jpeg");
        },
      });
    },
    generatePDF() {
      const PanPrint = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      const pageWidth = PanPrint.internal.pageSize.getWidth();
      const pageHeight = PanPrint.internal.pageSize.getHeight();
      const Width = pageWidth - 20;
      const Height = pageHeight / 2.5;

      PanPrint.addImage(
        this.passbookImageDestination,
        ["JPEG", "PNG"],
        10,
        10,
        Width,
        Height
      );

      this.savePDF(PanPrint);
    },
    savePDF(url) {
      this.pdfDataUrl = url.output("datauristring");
      this.pdfBlob = url.output("blob");
      this.pdfDownloadUrl = URL.createObjectURL(this.pdfBlob);
    },

    handlePassBookImageUpload(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.passBookImagePreview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.openDialog(this.passBookImagePreview);
      }
    },
  },
};
</script>
<style scoped>
.DestImageWidth {
  width: 400px;
  height: 500px;
}
</style>