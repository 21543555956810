<template>
  <div class="mt-10 flex justify-center h-screen">
    <div class="flex items-center max-w-fit shadow-sm h-full">
      <div class="w-full flex justify-center shadow-sm mb-4 border-2 border-primary">
        <div class="w-1/2 div1 flex justify-center items-start">
          <div class="bg-white rounded px-8 pt-6 pb-8 mb-4 w-full">
            <div class="w-full mb-4">
              <div class="mb-4">
                <input type="file" accept="image/*" @change="previewImage" v-if="afterOne" />
              </div>
              <div class="mb-4">
                <div class="img-container overflow-hidden bg-cover">
                  <img src="../assets/defaultimage.png" v-if="isVisible" class="w-36 h-44" />
                  <img ref="image" :src="preview" v-if="preview" crossorigin class="PreViewImg" @load="EditImage" />
                </div>
              </div>
              <div class="mb-4">
                <div class="items-center flex text-center">
                  <div class="inline-block relative max-w-fit text-center items-center">
                    <div class="flex">
                      <select
                        class="block appearance-none bg-white border border-primary hover:border-secondary px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline w-full"
                        v-model="numberOfPhotos">
                        <option value="" disabled selected>
                          Choose N of Photo &#9662;
                        </option>
                        <option value="1">3 Photo (4 x 6 inches)</option>
                        <option value="2">6 Photo (4 x 6 inches)</option>
                        <option value="3">4 Photo (5 x 7 inches)</option>
                        <option value="4">8 Photo (5 x 7 inches)</option>
                        <option value="5">6 Photo (210 x 297mm)</option>
                        <option value="6">12 Photo (210 x 297mm)</option>
                        <option value="7">24 Photo (210 x 297mm)</option>
                        <option value="8">8 Photo (4 x 6 Landscape)</option>
                      </select>
                    </div>
                  </div>

                  <div class="max-w-md w-full items-center">
                    <div class="text-center">
                      <button
                        class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        @click="triggerGeneratePDF">
                        Create Photo 
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-center">
                <div>
                  <img :src="destination" class="w-60 h-72" v-if="destination" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="div2 mb-4 w-1/2">
          <div class="h-fit px-2 py-6 w-full">
            <h2 class="text-xl font-bold mb-2 w-full">PDF Preview</h2>
            <div class="bg-white border border-gray-300 p-4 rounded-md">
              <object :data="pdfDataUrl" type="application/pdf" width="100%" height="560px">
                <p>Preview is not available.</p>
              </object>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import jsPDF from "jspdf";
import { mapActions,mapState } from 'vuex';


export default {
  name: "ImageCropper",
  data() {
    return {
      cropper: {},
      destination: "",
      image: {},
      ChooseImg: null,
      preview: null,
      isVisible: true,
      pdfDataUrl: "",
      pdfBlob: null,
      pdfDownloadUrl: "",
      numberOfPhotos: "",
      afterOne: true,

    };
  },
  props: {
    src: String,
  },

  computed: {
    ...mapState(['coins','isPremium']),
  },

  methods: {
    ...mapActions(['deductCoins']),



    async triggerGeneratePDF() {
      if (this.afterOne) {
        if (!this.isPremium) {
          const deductionResult = await this.deductCoins({ amount: 5 });
          if (deductionResult) {
            this.generatePDF()
            this.afterOne = false
          } else {
            alert("Insufficient Coin")
          }
        } else {
          this.generatePDF()
        }
      } else {
        this.generatePDF()
      }

    },


    generatePDF() {




      const photoA4 = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      const photo4x6 = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [102, 152],
      });
      const photo4x6L = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [102, 152],
      });
      const photo5x7 = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [127, 178],
      });

      const imgWidth5x7 = 26;
      const imgHeight5x7 = 36;

      const imgWidth4x6 = 28;
      const imgHeight4x6 = 38;

      const imgWidth4x6L = 32;
      const imgHeight4x6L = 42;

      const imgWidthA4 = 30; // Passport size width in mm
      const imgHeightA4 = 40; // Passport size height in mm

      const margin = 4; // Margin in mm
      let x = margin; // Starting x position
      let y = margin; // Starting y position

      if (this.numberOfPhotos == 1 || this.numberOfPhotos == 2) {
        if (this.numberOfPhotos == 1) {
          var N = 3;
        } else {
          N = 6;
        }
        for (let i = 0; i < N; i++) {
          photo4x6.addImage(
            this.destination,
            "JPEG",
            x,
            y,
            imgWidth4x6,
            imgHeight4x6
          );
          x += imgWidth4x6 + margin; // Increment x position
          if (i === 2) {
            x = margin;
            y += imgHeight4x6 + margin;
          }
        }
        this.savePDf(photo4x6);
      }

      if (this.numberOfPhotos == 3 || this.numberOfPhotos == 4) {
        if (this.numberOfPhotos == 3) {
          var N = 4;
        } else {
          N = 8;
        }
        for (let i = 0; i < N; i++) {
          photo5x7.addImage(
            this.destination,
            "JPEG",
            x,
            y,
            imgWidth5x7,
            imgHeight5x7
          );
          x += imgWidth5x7 + margin; // Increment x position
          if (i === 3) {
            x = margin;
            y += imgHeight5x7 + margin;
          }
        }
        this.savePDf(photo5x7);
      }

      // Loop through the images and add them to the PDF
      if (
        this.numberOfPhotos == 5 ||
        this.numberOfPhotos == 6 ||
        this.numberOfPhotos == 7
      ) {
        if (this.numberOfPhotos == 5) {
          var N = 6;
        }
        if (this.numberOfPhotos == 6) {
          N = 12;
        }
        if (this.numberOfPhotos == 7) {
          N = 24;
        }
        for (let i = 0; i < N; i++) {
          photoA4.addImage(
            this.destination,
            "JPEG",
            x,
            y,
            imgWidthA4,
            imgHeightA4
          );
          x += imgWidthA4 + margin; // Increment x position
          if (i === 5 || i === 11 || i === 17) {
            x = margin;
            y += imgHeightA4 + margin;
          }
        }
        this.savePDf(photoA4);
      }
      if (this.numberOfPhotos == 8) {
        for (let i = 0; i < 8; i++) {
          photo4x6L.addImage(
            this.destination,
            "JPEG",
            x,
            y + 2,
            imgWidth4x6L,
            imgHeight4x6L
          );
          x += imgWidth4x6L + margin + 1; // Increment x position
          if (i === 3) {
            x = margin + 1;
            y += imgHeight4x6L + margin + 1;
          }
        }
        this.savePDf(photo4x6L);
      }
    },
     savePDf(url) {

      this.pdfDataUrl = url.output("datauristring");
      this.pdfBlob = url.output("blob");
      this.pdfDownloadUrl = URL.createObjectURL(this.pdfBlob);


    },

    previewImage: function (event) {
      this.isVisible = false;
      this.afterOne = true
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.ChooseImg = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },

    EditImage() {
      this.image = this.$refs.image;
      this.cropper = new Cropper(this.image, {
        zoomable: true,
        scalable: false,
        aspectRatio: 1.6 / 2,
        crop: () => {
          const canvas = this.cropper.getCroppedCanvas();
          if (canvas.width === 0 || canvas.height === 0) {
            console.error("Invalid canvas dimensions");
            return;
          }
          // Create a new canvas with border
          const borderedCanvas = document.createElement("canvas");
          const ctx = borderedCanvas.getContext("2d");

          // Set border size and color
          const borderSize = 1; // in pixels
          const borderColor = "black";

          // Calculate new canvas size with border
          borderedCanvas.width = canvas.width + borderSize * 2;
          borderedCanvas.height = canvas.height + borderSize * 2;

          // Draw border
          ctx.fillStyle = borderColor;
          ctx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);

          // Draw cropped image
          ctx.drawImage(canvas, borderSize, borderSize);

          this.destination = borderedCanvas.toDataURL("image/jpeg");
        },

        // crop: () => {
        //   const canvas = this.cropper.getCroppedCanvas();
        //   this.destination = canvas.toDataURL("image/png");
        // },
      });
    },
  },
};
</script>

<style scoped>
.PreViewImg {
  width: 150px;
  height: 150px;
}
</style>
