<template>
    <div class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70">
        <div class="bg-white p-4 md:p-6 rounded-lg shadow-lg w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
            <h2 class="text-xl md:text-2xl font-semibold mb-4 text-teal-600 text-center">Terms and Conditions</h2>
            <!-- Apply max-height and overflow-y to the content container -->
            <div class="prose max-w-full max-h-[400px] overflow-y-auto">
                <div v-html="termsAndConditions"></div>
            </div>
            <!-- "Accept" button is always visible on all screen sizes -->
            <button @click="acceptTerms"
                class="mt-4 bg-teal-500 text-white px-4 py-2 rounded-full hover:bg-teal-600 focus:outline-none w-full">
                Accept
            </button>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            termsAndConditions: `
        <h3 class="text-center font-bold">Terms and Conditions (Short Version):</h3>
        <h4 class="text-center mb-4 font-bold">Welcome to the CSP Photo and Aadhaar Print App. By using the App, you agree to these terms:</h4>
        <ol class="list-decimal list-inside pl-6 text-start">
          <li class="mb-2">Use the App only for lawful purposes and don't interfere with its proper working.</li>
          <li class="mb-2">The developer and owner of the App are not responsible for any misuse of the facility to print sensitive documents manually.</li>
          <li class="mb-2">The accuracy of the information provided is your responsibility.</li>
          <li class="mb-2">The App and its content are protected by intellectual property laws.</li>
          <li class="mb-2">The App and its content are provided "as is" and without any warranties.</li>
          <li class="mb-2">The developer and owner of the App are not liable for any damages arising out of or in connection with the use of the App.</li>
          <li class="mb-2">You agree to indemnify, defend and hold harmless the developer and owner of the App.</li>
          <li class="mb-2">These terms are governed by the laws of India and any dispute will be subject to the exclusive jurisdiction of Indian courts.</li>
          <li class="mb-2">The developer and owner of the App can modify these terms and conditions at any time without prior notice.</li>
          <li class="mb-2">App is only provided as a service, and we do not create any document; any correction made by this app will not be valid.</li>
        
        </ol>
        <div class="text-start mx-4">
        <p>1. ऐप का उपयोग केवल वैध उद्देश्यों के लिए करें और उसके सही काम को अवरुद्ध न करें।</p>
        <p>2. ऐप के उपयोग से संबंधित संवेदनशील दस्तावेजों को मैन्युअल रूप से प्रिंट करने की सुविधा का दुरुपयोग किया जाना संभव है, इसके लिए ऐप के डेवलपर और मालिक ज़िम्मेदार नहीं होंगे।</p>
        <p>3. प्रदान की गई जानकारी की सटीकता आपकी ज़िम्मेदारी है।</p></div>
      `,
        };
    },
    methods: {
        acceptTerms() {
            // Handle the logic for accepting the terms and conditions here
            // You can use Vuex, emit an event, or perform an API request.
            // For this example, we'll just close the dialog.
            this.$emit('accepted'); // Emit an event to notify the parent component
        },
    },
};
</script>

<style scoped>
/* Additional styles can be applied here */
</style>
