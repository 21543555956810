<template>

<page-loader />

<Header v-if="showHeader"  />
<router-view></router-view>
<Footer v-if="showFooter" />
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import PageLoader from "./components/PageLoader.vue";
import store from './components/store';
import {  mapState  } from 'vuex';

export default {

  computed: {
    ...mapState(['coins', "isAuthenticated", "expiryTimestamp", "currentTimestamp"]),
  },

  name: 'App',
  components: {
    Header,
    Footer,
    PageLoader
  },
   data() {
    return {
      showHeader: true,
      showFooter: true,
      isLoading:true
    };
  },
  watch: {
    '$route'(to) {
      // Update the showHeader and showFooter values based on the route
      this.showHeader = !to.meta.hideHeader;
      this.showFooter = !to.meta.hideFooter;
    }
  },
  mounted(){
    this.$watch(
      () => [this.expiryTimestamp, this.currentTimestamp],
      () => {
        // Check if both expiryTimestamp and currentTimestamp are fetched
        if (this.expiryTimestamp !== null && this.currentTimestamp !== null) {

          const currentTimestampMillis = this.currentTimestamp * 1000;

          if (currentTimestampMillis < this.expiryTimestamp) {
            this.isLoading = false
            this.isPremium = true
           store.dispatch('setIsPremium',true);
          } else {
            this.isPremium = true
            this.isLoading = false
            store.dispatch('setIsPremium',false);
            store.dispatch('fetchCoins');
          
          }
        }
      }
    );
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

</style>
