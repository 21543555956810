<template>
  <div>
    <!-- Your Vue.js content here -->
    <div class="content">
      <h1>Welcome to My App</h1>


    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // AdMob banner will be displayed when this component is mounted
  },
};
</script>
