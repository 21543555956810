<template>
  <div class="min-h-screen">
    <div class="max-w-xl mx-auto p-8 text-center bg-red-100 border border-red-300 rounded-lg mt-20 ">
      <h1 class="text-3xl font-bold text-red-700 mb-6">Payment Failed</h1>
      <p class="text-lg mb-6">Unfortunately, your payment was not successful. Please try again or contact our support team for assistance.</p>
      <div class="text-left">
        <h2 class="text-2xl font-semibold mb-4">Contact Us</h2>
        <p class="mb-2">If you need any help, feel free to reach out to us:</p>
        <ul class="list-none p-0">
          <li class="mb-2"><strong>Email:</strong> <a href="mailto:kumarrajnish.stm@gmail.com" class="text-blue-600 hover:underline">kumarrajnish.stm@gmail.com</a></li>
          <li class="mb-2"><strong>Phone:</strong> <a href="https://t.me/cspphotoprint" class="text-blue-600 hover:underline">+91 7488150245</a></li>
        </ul>
      </div>
    </div>
 
  </div>
  </template>
  
  <script>
  export default {
    name: 'FailedPayment'
  };
  </script>
  
  <style scoped>
  /* No additional styles needed with Tailwind CSS */
  </style>
  