<template>
    <div>


        <div>
            <button @click="showTermsDialog" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                Show Terms and Conditions
            </button>

            <!-- <warning v-if="showDialog" @accepted="handleAcceptance" /> -->
        </div>

        <Hero />
        <DashBoard />
    </div>
</template>
<script>
import DashBoard from './DashBoard.vue';
import Hero from './Hero.vue';
import Warning from './Warning.vue';

export default {
    name: "Home",
    components: {
        DashBoard,
        Hero,
        Warning
    },
    data() {
        return {
            showDialog: true,
        };
    },
    methods: {
        showTermsDialog() {
            this.showDialog = true;
        },
        handleAcceptance() {
            // Handle acceptance logic here
            this.showDialog = false;
            // You can update a flag or perform other actions when the user accepts.
        },
    },
}
</script>
