<template>
  <footer class="bg-gray-100 py-4">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col sm:flex-row justify-between items-center">
        <div class="flex items-center mb-4 sm:mb-0">
          <img src="../assets/splace_icon.png" alt="CSP Logo" class="h-8 mr-4" />
          <p class="text-gray-700">CSP Photo & ManualAadhar <br>Print &copy; 2023</p>
        </div>
        <div class="flex flex-wrap justify-center sm:justify-end">
          <div class="mx-2 my-1 sm:my-0">
            <router-link to="/privacy-policy" class="text-gray-600 hover:text-gray-900">Privacy Policy</router-link>
          </div>
          <div class="mx-2 my-1 sm:my-0">
            <router-link to="/TermsAndConditions" class="text-gray-600 hover:text-gray-900">Terms of Service</router-link>
          </div>
          <div class="mx-2 my-1 sm:my-0">
            <router-link to="/CancellationAndRefundPolicy" class="text-gray-600 hover:text-gray-900">Refund Policy</router-link>
          </div>
          <div class="mx-2 my-1 sm:my-0">
            <router-link to="/shipping-and-delivery-policy" class="text-gray-600 hover:text-gray-900">Shipping</router-link>
          </div>
          <div class="mx-2 my-1 sm:my-0">
            <router-link to="/contact-us" class="text-gray-600 hover:text-gray-900">Contact Us</router-link>
          </div>
          <div class="mx-2 my-1 sm:my-0">
            <a href="https://t.me/cspphotoprint" class="text-gray-600 hover:text-gray-900">Download our Mobile App</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>
