<template>
  <div class="h-screen mt-16 flex justify-center items-center">
    <div class="flex flex-col item-center">
      <div class="flex justify-around items-center h-full w-full">
        <div class="flex justify-between border-2 border-secondary py-2 px-2">
          <div class="w-1/2 max-2">
            <div class="h-fit px-10 py-6 items-center w-full max-w-md mb-4">
              <form class="max-w-lg mx-auto" @submit.prevent="generatePDF">
                <h1 class="text-3xl font-bold capitalize mb-4">
                  Personal Details
                </h1>
                <div class="mb-4">
                  <label class="flex text-gray-700 font-bold mb-2" :for="name"
                    >Name</label
                  >
                  <input
                    class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    placeholder="Name"
                    v-model="name"
                    required
                  />
                </div>
                <div class="mb-4">
                  <label class="flex text-gray-700 font-bold mb-2" :for="father"
                    >Father's / Husband Name</label
                  >
                  <input
                    class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                    id="father"
                    type="Father's / Husband Name"
                    placeholder="Father's / Husband Name"
                    v-model="fatherName"
                    required
                  />
                </div>
                <div class="mb-4">
                  <label class="flex text-gray-700 font-bold mb-2" for="panNo">
                    Pan Number
                  </label>
                  <input
                    class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline uppercase"
                    id="panNo"
                    type="text"
                    placeholder="pan no"
                    v-model="panNo"
                    maxlength="10"
                    required
                  />
                </div>
                <div class="mb-4">
                  <label class="flex text-gray-700 font-bold mb-2" for="dob">
                    Date of Birth
                  </label>
                  <input
                    class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                    id="dob"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    v-model="dob"
                    required
                  />
                </div>

                <div class="flex">
                  <div class="mb-4">
                    <label
                      for="photo"
                      class="flex text-gray-700 font-bold mb-2"
                    >
                      Choose Photo
                    </label>
                    <input
                      id="photo"
                      type="file"
                      alt="Selected Image"
                      @change="handleImageUpload"
                      required
                    />
                  </div>
                </div>
                <div class="flex">
                  <div class="mb-4">
                    <label
                      for="signature"
                      class="flex text-gray-700 font-bold mb-2"
                    >
                      Choose Signature
                    </label>
                    <input
                      id="signature"
                      type="file"
                      alt="Selected Image"
                      @change="handleSignatureUpload"
                      required
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
               v-if="isWaterMakeRemoved || isPremium" >
                  Generate PDF
                </button>

                <button
                @click="removeWaterMark"
                  class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                v-else>
                 Pay Coins
                </button>



              </form>
            </div>
          </div>
          <div class="w-1/2 max-2">
            <div class="flex flex-col items-center w-full max-w-md mb-4">
              <div class="w-full max-w-md mb-4">
                <h2 class="text-xl font-bold mb-2">PDF Preview</h2>
                <div
                  class="bg-white border border-primary p-4 shadow-sm rounded-md"
                >
                  <object
                    :data="pdfDataUrl"
                    type="application/pdf"
                    width="100%"
                    height="560px"
                  >
                    <p>Preview is not available.</p>
                  </object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Dialog box -->
    <modal v-if="isDialogOpen" @close="closeDialog">
      <div class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-8 border-2 border-primary">
          <div class="flex justify-start">
            <div class="mx-4" v-if="imagePreview">
              <img
                :src="imagePreview"
                alt="Image"
                class="DestImageWidth"
                ref="image"
                @load="EditImage(2, 1.6)"
              />
            </div>
            <div class="mx-4" v-if="SignatureImg">
              <img
                :src="SignatureImg"
                alt="Image"
                class="DestImageWidth"
                ref="image"
                @load="EditImage(1, 3)"
              />
            </div>
            <div v-if="imagePreview">
              <img :src="destination" alt="Image" class="DestImageWidth" />
            </div>
            <div v-if="SignatureImg">
              <img :src="SignDestination" alt="Image" class="DestImageWidthForSig" />
            </div>
          </div>
          <div class="justify-center mt-4">
            <button
              @click="saveImage"
              class="bg-primary text-white font-bold py-2 px-4 rounded"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import QRCode from "qrcode";
import jsPDF from "jspdf";
import Cropper from "cropperjs";
import { mapActions,mapState } from 'vuex';


export default {
  methods: {
    ...mapActions(['deductCoins']),
    openDialog(e) {
      // Set the initial image URL for display in the dialog
      this.dialogImageUrl = e;

      // Open the dialog
      this.isDialogOpen = true;
    },
    saveImage() {
      // Save the edited image data
      // Assign dialogImageUrl to imageData
      this.imageData = this.dialogImageUrl;

      // Close the dialog
      this.isDialogOpen = false;
      this.imagePreview = null;
      this.SignatureImg = null;
    },

    generatePDF() {
      const PanPrint = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      const width = 170;
      const height = 65;

      const PhotoWidth = 11;
      const PhotoHeight = 15;

      const QrPhotoWidth = 24;
      const QrPhotoHeight = 25;

      const signPhotoWidth = 25;
      const signPhotoHeight = 4;

      // Convert PAN number to uppercase
      const panNoUpperCase = this.panNo.toUpperCase();

      PanPrint.addImage(this.PanImage, "JPEG", 15, 4, width, height);
      PanPrint.addImage(
        this.destination,
        ["JPEG", "PNG"],
        20,
        25,
        PhotoWidth,
        PhotoHeight
      );
      PanPrint.addImage(
        this.QrImageData,
        ["JPEG", "PNG"],
        72,
        24,
        QrPhotoWidth,
        QrPhotoHeight
      );
      PanPrint.addImage(
        this.SignDestination,
        ["JPEG", "PNG"],
        45,
        58,
        signPhotoWidth,
        signPhotoHeight
      );

      PanPrint.setFontSize(8);
      PanPrint.setFont("bold");
      PanPrint.text(panNoUpperCase, 43, 34);
      PanPrint.setFontSize(7);

      PanPrint.text(this.name, 21, 45);
      PanPrint.text(this.fatherName, 21, 53);

      PanPrint.text(this.dob, 21, 64);

      this.savePDF(PanPrint);
    },
    savePDF(url) {
      this.pdfDataUrl = url.output("datauristring");
      this.pdfBlob = url.output("blob");
      this.pdfDownloadUrl = URL.createObjectURL(this.pdfBlob);
    },


    async removeWaterMark() {
    
    const deductionResult = await this.deductCoins({ amount: 10 });
    if (deductionResult) {
      this.isWaterMakeRemoved = true
    } else {
      alert("Insuffricient coins");

    }
  },


    EditImage(e, g) {
      this.image = this.$refs.image;
      this.cropper = new Cropper(this.image, {
        zoomable: true,
        scalable: false,
        aspectRatio: g===3 ? NaN : g / e,
        crop: () => {
          const canvas = this.cropper.getCroppedCanvas();

          // Check if the canvas has valid dimensions
          if (canvas.width === 0 || canvas.height === 0) {
            console.error("Invalid canvas dimensions");
            return;
          }

          // Create a new canvas with border
          const borderedCanvas = document.createElement("canvas");
          const ctx = borderedCanvas.getContext("2d");

          // Set border size and color
          const borderSize = 1; // in pixels
          const borderColor = "black";

          // Calculate new canvas size with border
          borderedCanvas.width = canvas.width + borderSize * 2;
          borderedCanvas.height = canvas.height + borderSize * 2;

          // Draw border
          ctx.fillStyle = borderColor;
          ctx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);

          // Draw cropped image
          ctx.drawImage(canvas, borderSize, borderSize);
          if (g == 3) {
            this.SignDestination = borderedCanvas.toDataURL("image/jpeg");
          } else {
            this.destination = borderedCanvas.toDataURL("image/jpeg");
          }
        },
      });
    },

    handleImageUpload(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.QrCodeGenerate();
        this.openDialog(this.imagePreview);
      }
    },
    handleSignatureUpload(event) {
      this.isWaterMakeRemoved = false
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.SignatureImg = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.openDialog(this.SignatureImg);
      }
    },

    async QrCodeGenerate() {
      const certificateInfo = `Name: ${this.name}\n DOB: ${this.dob}\n Aadhar No: ${this.adhar}`;
      const qrCodeImageUrl = await QRCode.toDataURL(certificateInfo, {
        scale: 5,
      });
      this.QrImageData = qrCodeImageUrl;
    },
  },
  
  data() {
    return {
      dob: "",
      name: "",
      fatherName: "",
      image: {},
      destination: "",
      panNo: "",
      PanImage: require("../assets/pan_card_try1.png"),
      imageSelect: null,
      imagePreview: null,
      QrImageData: "",
      pdfDataUrl: "",
      pdfBlob: null,
      pdfDownloadUrl: "",
      SignatureImg: null,
      SignDestination:null,
      isDialogOpen: false,
      dialogImageUrl: "", // Image URL to be displayed in the dialog
      imageData: "", // Variable to store the edited image data
      photos: require("../assets/voter_icon.png"),
      isWaterMakeRemoved:false,


    };
  },
  computed: {

    ...mapState(['coins','isPremium']),

    formattedDOB() {
      if (!this.dob) {
        return "";
      }
      const date = new Date(this.dob);
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      const day = `${date.getDate()}`.padStart(2, "0");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
<style scoped>
.DestImageWidth {
  width: 400px;
  height: 500px;
}
.DestImageWidthForSig {
  width: 400px;
  height: 60px;
}
</style>