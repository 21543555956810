import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import store from './store';



const firebaseConfig = {
    apiKey: 'AIzaSyB10w5n5DKMA3jkT8_GzYL7mc7hwyLi-88',
    authDomain: 'csp-photo-print.firebaseapp.com',
    databaseURL: "https://csp-photo-print-default-rtdb.firebaseio.com",
    projectId: "csp-photo-print",
    storageBucket: "csp-photo-print.appspot.com",
    messagingSenderId: "16065354030",
    appId: "1:16065354030:web:a071d8cd4c3bae4aba42bf",
    measurementId: "G-7RGVS56Y2B"
};


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Get the Auth instances
const auth = getAuth(firebaseApp);

auth.onAuthStateChanged(user => {
    if (user) {
        // User is signed in
        store.dispatch('setUid', user.uid);
        store.dispatch('fetchExpiryTimestam');
        store.dispatch('fetchCurrentTimestamp');
        store.dispatch('setIsAuthenticated', true);
      
    } else {
        // User is signed out
        // You can handle sign-out state or redirect to sign-in page
        store.dispatch('setUid', localStorage.getItem("userId"));
        store.dispatch('fetchExpiryTimestam');
        store.dispatch('fetchCurrentTimestamp');
        store.dispatch('setIsAuthenticated', false);
        console.log('User is signed out');
    }
});

export { auth };