<template>
  <header class="fixed w-full">
    <alternative-header />
  </header>

  <div class="h-screen mx-auto items-center justify-center flex">
    <div class="mt-2 main_div_width mx-auto flex border-2 border-primary py-2">
      <div class="border-secondary border-2 py-2 px-4 mx-3">
        <form class="max-w-lg mx-auto" @submit.prevent="submitForm">
          <div v-if="currentPage === 1">
            <h1 class="text-xl font-bold capitalize mb-4 mx-24">
              Personal Details
            </h1>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" :for="name"
                >Name</label
              >
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Name"
                v-model="name"
                required
              />
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="adhar">
                Voter Number
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="adhar"
                type="text"
                placeholder="1234 5678 9012"
                v-model="adhar"
                @input="formatAdhar"
                maxlength="10"
                required
              />
            </div>
            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="dob">
                Date of Birth
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="dob"
                type="date"
                placeholder="YYYY-MM-DD"
                v-model="dob"
                required
              />
            </div>
            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="gender"
                >Gender</label
              >
              <div class="flex">
                <label class="mr-4">
                  <input
                    type="radio"
                    class="mr-2"
                    name="gender"
                    value="पुरुष / Male"
                    v-model="gender"
                    @change="updateName"
                  />
                  Male
                </label>
                <label>
                  <input
                    type="radio"
                    class="mr-2"
                    name="gender"
                    value="महिला / Female"
                    v-model="gender"
                    @change="updateName"
                  />
                  Female
                </label>
              </div>
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="dob">
                Photo
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                type="file"
                accept="image/*"
                @change="handleImageUpload"
              />
            </div>

            <button
              type="button"
              class="bg-primary hover:bg-secondary text-white font-bold py-2 px-10 rounded"
              @click="currentPage = 2"
            >
              Next
            </button>
          </div>

          <div v-if="currentPage === 2">
            <h1 class="text-xl font-bold capitalize mb-4">Address Details</h1>

            <div class="mb-4">
              <label
                class="flex text-gray-700 font-bold mb-2"
                for="SelectParent"
                >Name Print On Card</label
              >
              <div class="flex">
                <label class="mr-4">
                  <input
                    type="radio"
                    class="mr-2"
                    name="SelectParent"
                    value="Husband"
                    v-model="SelectParent"
                    @change="updateName"
                  />
                  Husband
                
                </label>
                <label>
                  <input
                    type="radio"
                    class="mr-2"
                    name="SelectParent"
                    value="Father"
                    v-model="SelectParent"
                    @change="updateName"
                  />
                  Father
                </label>
              </div>
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" :for="father"
                >Father's / Husband Name</label
              >
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="father"
                type="Father's / Husband Name"
                placeholder="Father's / Husband Name"
                v-model="fatherName"
                required
              />
            </div>

            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="house-no"
                  >House No / Ward No</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="house-no"
                  type="text"
                  placeholder="House No / Ward No"
                  v-model="houseNo"
                  required
                />
              </div>

              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="street"
                  >Street/Locality/Village</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="street"
                  type="text"
                  placeholder="Street/Locality/Village"
                  v-model="street"
                  required
                />
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mb-4">
                <label
                  class="flex text-gray-700 font-bold mb-2"
                  for="post-office"
                  >Post Office</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="post-office"
                  type="text"
                  placeholder="Post Office"
                  v-model="postOffice"
                  required
                />
              </div>

              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="district"
                  >District</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="district"
                  type="text"
                  placeholder="District"
                  v-model="district"
                  required
                />
              </div>
            </div>

            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="state"
                  >State</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="state"
                  type="text"
                  placeholder="State"
                  v-model="state"
                  required
                />
              </div>

              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode"
                  >Pincode</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode"
                  type="text"
                  placeholder="Pincode"
                  v-model="pincode"
                  required
                />
              </div>
            </div>

            <div class="flex justify-between">
              <button
                type="button"
                class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="currentPage = 1"
              >
                Previous
              </button>
              <button
                type="button"
                class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="currentPage = 3"
              >
                Next
              </button>
            </div>
          </div>

          <div v-if="currentPage === 3">
            <h1 class="text-xl font-bold capitalize mb-4">
              Address Details In Local
            </h1>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" :for="father"
                >अपना नाम दर्ज करें
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="father"
                type="Father's / Husband Name"
                placeholder="अपना नाम दर्ज करें  "
                v-model="NameInHindi"
                required
              />
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" :for="father"
                >पिता / पति का नाम
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="father"
                type="Father's / Husband Name"
                placeholder="पिता / पति का नाम"
                v-model="FatherNameInHindi"
                required
              />
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="pincode"
                >मकान संख्या / वार्ड संख्या</label
              >
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="pincode"
                type="text"
                placeholder="अपना मकान संख्या / वार्ड संख्या दर्ज करें"
                v-model="WardInHIndi"
                required
              />
            </div>

            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode"
                  >गली / मोहल्ला / गांव</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode"
                  type="text"
                  placeholder="अपनी गली / मोहल्ला / गांव दर्ज करें"
                  v-model="HouseInHIndi"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode"
                  >डाकघर</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode"
                  type="text"
                  placeholder="डाकघर"
                  v-model="PostOfficeInHIndi"
                  required
                />
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode"
                  >जिला</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode"
                  type="text"
                  placeholder="अपना जिला दर्ज करें"
                  v-model="DistHIndi"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode"
                  >राज्य</label
                >
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode"
                  type="text"
                  placeholder="अपना राज्य दर्ज करें"
                  v-model="StateInHIndi"
                  required
                />
              </div>
            </div>

            <div class="flex justify-between">
              <button
                type="button"
                class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="currentPage = 2"
              >
                Previous
              </button>
              <button
                type="button"
                class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="currentPage = 4"
              >
                Next
              </button>
            </div>
            <div class="flex flex-col items-center"></div>
          </div>

          <div v-if="currentPage === 4">
            <h1 class="text-xl font-bold capitalize mb-4 mx-24 my-2">
              Voter Id Details
            </h1>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" :for="father"
                >Part No. & Name</label
              >
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="father"
                type="Father's / Husband Name"
                placeholder="Part No. & Name"
                v-model="PartName"
                required
              />
            </div>
            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="street"
                >Assembly Constituency No. & Name</label
              >
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="street"
                type="text"
                placeholder="Assembly Constituency No. & Name"
                v-model="AssemblyName"
                required
              />
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="house-no"
                >भाग संख्या और नाम
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="house-no"
                type="text"
                placeholder="भाग संख्या और नाम"
                v-model="PartNameInHIndi"
                required
              />
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="pincode"
                >विधानसभा निर्वाचन क्षेत्र संख्या और नाम
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="pincode"
                type="text"
                placeholder="विधानसभा निर्वाचन क्षेत्र संख्या और नाम"
                v-model="AssemblyNameInHIndi"
                required
              />
            </div>
            <div class="flex justify-between">
              <button
                type="button"
                class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="currentPage = 3"
              >
                Previous
              </button>
              <button
                type="submit"
                class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="printPdf()" v-if="isWaterMakeRemoved || isPremium"
              >
                Generate PDF
              </button>

              <button
                type="submit"
                class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="removeWaterMark()" v-else
              >
                Pay Coins
              </button>

            </div>
          </div>
        </form>
      </div>

      <div class="mb-4 main_div_width w-1/2 canvas">
        <div class="relative AdCanvas" id="AdCanvasId" ref="content">
          <div class="relative inset-0 main_div">
            <img
              class="object-fit pdf-image"
              :src="VoterImg"
              alt="Aadhaar card"
            />
          </div>
          <div class="absolute top-20 mt-1 left-44 ms-2 AdharNumber text-start">
            {{ this.adhar }}
          </div>
          <img
            v-if="PhotoDestination"
            :src="PhotoDestination"
            alt="hi"
            class="destinationPhotoWidth absolute top-24 left-20 mt-4 ms-1"
          />
          <div
            class="absolute top-96 mt-3 ms-6 item-start justify-start w-fit CommonTextFontS"
            style="margin-top: 9px"
          >
            {{ this.ParentType }}
          </div>
          <div
            class="absolute top-96 -mt-6 ms-6 item-start justify-start w-fit CommonTextFontS"
          >
            {{ this.ParentTypeInHindi }}
          </div>

          <div class="absolute top-12 left-28 text-start mt-60">
            <div class="font-sm CommonTextFont h-5">
              <p class="h-4">{{ this.NameInHindi }}</p>
              <p class="mt-5 h-4">{{ this.name }}</p>
              <p class="mt-5 h-5">{{ this.FatherNameInHindi }}</p>
              <p class="mt-3 h-4">{{ this.fatherName }}</p>
            </div>
          </div>
      

        <div
          class="relative ms-1 text-start -top-28 CommonTextFontA"
          style="left: 315px; top: -119px"
        >
          <p class="-mt-2 h-3" style="margin-start: 110px; margin-top: -6px">
            {{ this.gender }}
          </p>
          <p
            class="mt-1 ms-24 h-3"
            style="margin-start: 110px; margin-top: 6px"
          >
            {{ this.dob }}
          </p>

          <div class="w-52 h-7 CommonTextFontA mt-2" style="line-height: 1.6">
            {{ this.soInHindi }} {{ this.FatherNameInHindi }}
            {{ this.WardInHIndi }} {{ this.HouseInHIndi }}
            {{ this.PostOfficeInHIndi }} {{ this.DistHIndi }}
            {{ this.StateInHIndi }} {{ this.pincode }}
          </div>

          <div class="w-52 h-8 CommonTextFontA" style="line-height: 1.6">
            Address :
            {{ this.so }} {{ this.fatherName }} {{ this.houseNo }}
            {{ this.street }} {{ this.postOffice }} {{ this.district }}
            {{ this.state }} {{ this.pincode }}
          </div>

          <div
            class="w-14 h-4 ms-6 CommonTextFontA"
            style="line-height: 1.6; margin-top: 3px"
          >
            {{ this.currentDate }}
          </div>
          <div
            class="w-52 h-4 CommonTextFontA"
            style="line-height: 1.6; margin-top: 26px"
          >
            {{ this.AssemblyNameInHIndi }}
          </div>
          <div
            class="w-52 h-4 CommonTextFontA"
            style="line-height: 1.6; margin-top: 5px"
          >
            {{ this.AssemblyName }}
          </div>
          <div
            class="w-24 h-4 ms-20 CommonTextFontA"
            style="line-height: 1.6; margin-top: 1px"
          >
            {{ this.PartNameInHIndi }}
          </div>
          <div
            class="w-52 h-4 CommonTextFontA"
            style="
              line-height: 1.6;
              margin-top: -3px;
              margin-inline-start: 73px;
            "
          >
            {{ this.PartName }}
          </div>
        </div>
      </div>
    </div>
    </div>

    <!-- Dialog box -->
    <modal v-if="isDialogOpen" @close="closeDialog">
      <div class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-8 border-2 border-primary">
          <div class="flex justify-start">
            <div class="mx-4" v-if="Photo">
              <img
                :src="Photo"
                alt="Image"
                class="DestImageWidth"
                ref="image"
                @load="EditImage"
              />
            </div>

            <div v-if="Photo">
              <img :src="PhotoDestination" alt="Image" class="DestImageWidth" />
            </div>
          </div>
          <div class="justify-center mt-4">
            <button
              v-if="Photo"
              @click="saveImage"
              class="bg-primary text-white font-bold py-2 px-4 rounded"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>


<script>
import Cropper from "cropperjs";
import Header from "./Header.vue";
import AlternativeHeader from "./AlternativeHeader.vue";
import { mapActions ,mapState} from 'vuex';

export default {
  mounted() {
    this.getCurrentDate();
    this.retrieveData();
    if(this.isPremium){
      this.VoterImg = require("../assets/voter_dummy_image.png")
    }
  },
  methods: {

    ...mapActions(['deductCoins']),

    saveData() {
      localStorage.setItem("name", this.name);
      localStorage.setItem("adhar", this.adhar);
      localStorage.setItem("dob", this.dob);
      localStorage.setItem("fatherName", this.fatherName);
      localStorage.setItem("gender", this.gender);
      localStorage.setItem("street", this.street);
      localStorage.setItem("houseNo", this.houseNo);
      localStorage.setItem("postOffice", this.postOffice);
      localStorage.setItem("district", this.district);
      localStorage.setItem("state", this.state);
      localStorage.setItem("pincode", this.pincode);

      localStorage.setItem("WardInHIndi", this.WardInHIndi);
      localStorage.setItem("NameInHindi", this.NameInHindi);
      localStorage.setItem("FatherNameInHindi", this.FatherNameInHindi);
      localStorage.setItem("HouseInHIndi", this.HouseInHIndi);
      localStorage.setItem("PostOfficeInHIndi", this.PostOfficeInHIndi);
      localStorage.setItem("DistHIndi", this.DistHIndi);
      localStorage.setItem("StateInHIndi", this.StateInHIndi);

      localStorage.setItem("PartName", this.PartName);
      localStorage.setItem("PartNameInHIndi", this.PartNameInHIndi);
      localStorage.setItem("AssemblyName", this.AssemblyName);
      localStorage.setItem("AssemblyNameInHIndi", this.AssemblyNameInHIndi);
      // You can use sessionStorage instead if you want the data to be available only for the current session
    },
    retrieveData() {
      this.name = localStorage.getItem("name") || "";
      this.adhar = localStorage.getItem("adhar") || "";
      this.dob = localStorage.getItem("dob") || "";
      this.fatherName = localStorage.getItem("fatherName") || "";
      this.gender = localStorage.getItem("gender") || "";
      this.street = localStorage.getItem("street") || "";
      this.houseNo = localStorage.getItem("houseNo") || "";
      this.postOffice = localStorage.getItem("postOffice") || "";
      this.district = localStorage.getItem("district") || "";
      this.state = localStorage.getItem("state") || "";
      this.pincode = localStorage.getItem("pincode") || "";

      this.WardInHIndi = localStorage.getItem("WardInHIndi") || "";
      this.NameInHindi = localStorage.getItem("NameInHindi") || "";
      this.FatherNameInHindi = localStorage.getItem("FatherNameInHindi") || "";
      this.HouseInHIndi = localStorage.getItem("HouseInHIndi") || "";
      this.PostOfficeInHIndi = localStorage.getItem("PostOfficeInHIndi") || "";
      this.DistHIndi = localStorage.getItem("DistHIndi") || "";
      this.StateInHIndi = localStorage.getItem("StateInHIndi") || "";

      this.PartName = localStorage.getItem("PartName") || "";
      this.PartNameInHIndi = localStorage.getItem("PartNameInHIndi") || "";
      this.AssemblyName = localStorage.getItem("AssemblyName") || "";
      this.AssemblyNameInHIndi =
        localStorage.getItem("AssemblyNameInHIndi") || "";
    },
    updateName() {
      if (this.SelectParent === "Father") {
        // Set values when "Father" is selected
        this.ParentType = "Father's Name";
        this.ParentTypeInHindi = "पिता का नाम ";
      } else if (this.SelectParent === "Husband") {
        // Set values when "Husband" is selected
        this.ParentType = "Husband's Name";
        this.ParentTypeInHindi = "पति का नाम";
      }
    },

    getCurrentDate() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0") + "-";
      const month = String(date.getMonth() + 1).padStart(2, "0") + "-";
      const year = date.getFullYear();
      this.currentDate = day + month + year;
    },

    async removeWaterMark() {
    
    const deductionResult = await this.deductCoins({ amount: 10 });
    if (deductionResult) {
      this.isWaterMakeRemoved = true
      this.VoterImg = require("../assets/voter_dummy_image.png")
    } else {
      alert("Insuffricient coins");

    }
  },

    printPdf() {
      this.saveData();

      if (typeof window.print === "function") {
        window.print();
      } else {
        alert("Please use any another browser");
      }
    },
    openDialog(e) {
      // Set the initial image URL for display in the dialog
      this.dialogImageUrl = e;
      // Open the dialog
      this.isDialogOpen = true;
    },
    saveImage() {
      // Save the edited image data
      // Assign dialogImageUrl to imageData
      this.imageData = this.dialogImageUrl;
      this.Photo = null;
      // Close the dialog
      this.isDialogOpen = false;
    },
    handleImageUpload(event) {
     
      if(!this.isPremium){
        this.isWaterMakeRemoved = false,
      this.VoterImg = require("../assets/voter_dummy_image_water_mark.png")
      }
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.Photo = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.openDialog(this.Photo);
      }
   
    },
    EditImage() {
      this.image = this.$refs.image;
      this.cropper = new Cropper(this.image, {
        zoomable: true,
        scalable: false,
        rotatable: true,
        aspectRatio:"1.6/2",
        crop: () => {
          const canvas = this.cropper.getCroppedCanvas();
          if (canvas.width === 0 || canvas.height === 0) {
            console.error("Invalid canvas dimensions");
            return;
          }
          // Assign the cropped image to the PhotoDestination variable
          this.PhotoDestination = canvas.toDataURL("image/jpeg");
        },
      });
    },
  },

  data() {
    return {
      currentPage: 1,
      currentDate: null,
      name: "",
      Photo: "",
      adhar: "",
      gender: "",
      dob: "",
      fatherName: "",
      SelectParent: "",
      ParentType: "",
      houseNo: "",
      street: "",
      postOffice: "",
      district: "",
      state: "",
      pincode: "",
      // in HIndi
      ParentTypeInHindi: "",
      WardInHIndi: "",
      NameInHindi: "",
      FatherNameInHindi: "",
      HouseInHIndi: "",
      PostOfficeInHIndi: "",
      DistHIndi: "",
      StateInHIndi: "",

      //voter
      PartName: "",
      PartNameInHIndi: "",
      AssemblyName: "",
      AssemblyNameInHIndi: "",

      isDialogOpen: false,
      dialogImageUrl: "",
      PhotoDestination: "",
      VoterImg: require("../assets/voter_dummy_image_water_mark.png"),
      isWaterMakeRemoved:false,
 
    };
  },
  computed: {
    ...mapState(['isPremium']),
    formattedDOB() {
      if (!this.dob) {
        return "";
      }
      const date = new Date(this.dob);
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      const day = `${date.getDate()}`.padStart(2, "0");
      return `${day}/${month}/${year}`;
    },
  },
  components: { Header, AlternativeHeader },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}
.pdf-image {
  width: 600px;
}

.main_div {
  width: 600px;
  height: 150px;
}
.AdharNumber {
  font-size: 14px;
  font-weight: bolder;
  color: #000;
}
span {
  color: red;
}
.CommonTextFontS {
  font-size: 10px;
  color: #000000;
  font-weight: bolder;
  letter-spacing: -0.8px;
}

.CommonTextFont {
  font-size: 12px;
  color: #000000;
  font-weight: bolder;
}
.CommonTextFontD {
  font-size: 12px;
  color: #000000;
}
.CommonTextFontA {
  font-size: 8px;
  font-weight: bold;
  color: #000000;
}

@media print {
  @page {
    margin-inline-end: 35mm;
    margin-top: 5mm;
  }

  body * {
    visibility: hidden;
  }

  .AdCanvas,
  .AdCanvas * {
    visibility: visible;
  }
  .AdCanvas {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.destinationPhotoWidth {
  width: 134px;
  height: 165px;
}
.DestImageWidth {
  width: 400px;
  height: 500px;
}
</style>
