<template>
  <header>
    <alternative-header />


  </header>

  <div class="max:h-screen mx-auto justify-center flex">


    <div class=" mt-2 main_div_width mx-auto ">
      <div class="mb-4 main_div_width canvas">
        <div class="relative AdCanvas " id="AdCanvasId" ref="content">
          <div class="relative inset-0 main_div w-80 ">

            <img class="object-fit pdf-image" :src="AadharImg" alt="Aadhaar card" />

          </div>
          <img v-if="PhotoDestination" :src="PhotoDestination" alt="hi"
            class="destinationPhotoWidth absolute  left-12" />



          <div class="absolute top-12 left-36 ms-1 text-start">

            <p class="CommonTextFontDAlone transform origin-bottom-left -rotate-90  w-52  ">
              Aadhaar no.issued:{{ formattedIssueDate }}
            </p>

            <div class="font-sm text-black-900 w-52 CommonTextFont -mt-48  h-5">
              {{ "भारत सरकार " }}
            </div>

            <div class="font-sm text-black-900 w-52 CommonTextFont -mt-1 -ms-6 h-5">
              {{ "Government of India " }}
            </div>

            <div class="font-sm text-black-900 w-52 CommonTextFont mt-4 h-5">
              {{ this.NameInHindi }}
            </div>
            <div class="text-sm text-black-900 w-52 -mt-1 CommonTextFont h-5">
              {{ this.name }}
            </div>
            <div class="text-sm text-black-900 w-48 CommonTextFont h-5" v-if="dob">
              जन्म तिथि / DOB :{{ formattedDOB }}

            </div>
            <div class="text-sm text-black-900 w-48 CommonTextFont h-5" v-else></div>
            <div class="text-sm text-black-900 w-24 CommonTextFont h-5">
              {{ gender }}
            </div>
            <div class="w-40 max-h-4 AdharNumber -ms-1 h-8" style="margin-top: 72px">
              {{ this.adhar }}
            </div>
            <div class="mt-4 -ms-5 meraAadhaar w-56">
              मेरा <span>आधार</span>, मेरी पहचान
            </div>
          </div>

          <div class="absolute top-28 ms-1 text-start" style="left: 450px">


            <div class="font-sm text-black-900 w-52 CommonTextFont -mt-24 ms-16  max-h-4">
              भारतीय विशिष्ट पहचान प्राधिकरण
            </div>
            <div class="font-sm text-black-900 w-52 CommonTextFont -mt-18 ms-16  max-h-4">
              Unique Identification Athority of India
            </div>

            <img v-if="QrImageData" :src="QrImageData" class="w-32 h-32 absolute -top-12 qrCodeImageView" />


            <p class="DetailsAsOfNowFontDAlone transform origin-bottom-left -rotate-90  w-52  ">
              Details as on: {{ formattedDetailsAsOn }}
            </p>

            <div class="font-sm text-black-900 w-24  CommonTextFont -mt-36 -ms-2 max-h-4">
              पता :
            </div>

            <div class="text-sm text-black-900 w-60 h-16 CommonTextFontD -ms-2 ">
              {{ this.soInHindi }} {{ this.FatherNameInHindi }}
              {{ this.WardInHIndi }} {{ this.HouseInHIndi }}
              {{ this.PostOfficeInHIndi }} {{ this.DistHIndi }}
              {{ this.StateInHIndi }} {{ this.pincode }}
            </div>


            <div class="font-sm text-black-900 w-24  CommonTextFont -ms-2 max-h-4">
              Address :
            </div>
            <div class="text-sm text-black-900 w-60 CommonTextFontD h-16 -ms-2">
              {{ this.so }} {{ this.fatherName }} {{ this.houseNo }}
              {{ this.street }} {{ this.postOffice }} {{ this.district }}
              {{ this.state }} {{ this.pincode }}
            </div>
            <div class="w-60  AdharNumber ms-28" v-if="adhar">{{ this.adhar }}</div>
            <div class="w-60  AdharNumber ms-28 h-6" v-else></div>

            <div class="text-sm text-black-900 w-96  flex  CommonTextFontForHeading">
              <div class="w-40 ms-7">1947</div>
              <div class="w-72 ms-4">help@uidai.gov.in</div>
              <div class="w-60 ms-2">www.uidai.gov.in</div>
            </div>
          </div>
        </div>
      </div>

      <div class="border-primary border-2 mt-10 py-2  mx-3">
        <form class="max-w-lg mx-auto" @submit.prevent="submitForm">
          <div v-if="currentPage === 1">
            <h1 class="text-xl font-bold capitalize mb-4">Personal Details</h1>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" :for="name">Name</label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="name" type="text" placeholder="Name" v-model="name" required />
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="adhar">
                Aadhaar Number
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="adhar" type="text" placeholder="1234 5678 9012" v-model="adhar" @input="formatAdhar" maxlength="14"
                required />
            </div>
            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="dob">
                Date of Birth
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="dob" type="date" placeholder="YYYY-MM-DD" v-model="dob" required />
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="dob">
                Photo
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                type="file" accept="image/*" @change="handleImageUpload" />
            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="gender">Gender</label>
              <div class="flex">
                <label class="mr-4">
                  <input type="radio" class="mr-2" name="gender" value="पुरुष / MALE" v-model="gender" />
                  Male
                </label>
                <label>
                  <input type="radio" class="mr-2 " name="gender" value="महिला / Female" v-model="gender" />
                  Female
                </label>
              </div>
            </div>

            <button type="button" class="bg-primary hover:bg-secondary text-white font-bold py-2 px-10 rounded"
              @click="currentPage = 2">
              Next
            </button>
          </div>

          <div v-if="currentPage === 2">
            <h1 class="text-xl font-bold capitalize mb-4">Address Details</h1>

            <div class="flex  justify-between">

              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="so">S/O , D/O , W/O</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="so" type="text" placeholder="S/O" v-model="so" required />
              </div>

              <div class="mb-4 ">
                <label class="flex  font-bold mb-2" for="issueDate">
                 Aadhar No issue Date
                </label>
              
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="issueDate" type="date" placeholder="DD-MM-YYYY" v-model="adharNumberIssueDate" />
              </div>

        

            </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" :for="father">Father's / Husband Name</label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="father" type="Father's / Husband Name" placeholder="Father's / Husband Name" v-model="fatherName"
                required />
            </div>

            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="house-no">House No / Ward No</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="house-no" type="text" placeholder="House No / Ward No" v-model="houseNo" required />
              </div>

              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="street">Street/Locality/Village</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="street" type="text" placeholder="Street/Locality/Village" v-model="street" required />
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="post-office">Post Office</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="post-office" type="text" placeholder="Post Office" v-model="postOffice" required />
              </div>

              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="district">District</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="district" type="text" placeholder="District" v-model="district" required />
              </div>
            </div>

            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="state">State</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="state" type="text" placeholder="State" v-model="state" required />
              </div>

              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode">Pincode</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode" type="text" placeholder="Pincode" v-model="pincode" required />
              </div>
            </div>

            <div class="flex justify-between">
              <button type="button" class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="currentPage = 1">
                Previous
              </button>
              <button type="button" class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="currentPage = 3">
                Next
              </button>
            </div>
          </div>

          <div v-if="currentPage === 3">
            <h1 class="text-xl font-bold capitalize mb-4">
              Address Details In Local
            </h1>
            <div class="flex  justify-between">
            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" for="so">पुत्र , पुत्री , अर्धांगिनी
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="so" type="text" placeholder="S/O" v-model="soInHindi" required />
            </div>

            <div class="mb-4 ">
                <label class="flex  font-bold mb-2" for="issueDate">
                 Details as on:
                </label>
              
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="issueDate" type="date" placeholder="DD-MM-YYYY" v-model="detalsAsOn" />
              </div>
              </div>

            <div class="mb-4">
              <label class="flex text-gray-700 font-bold mb-2" :for="father">अपना नाम दर्ज करें
              </label>
              <input
                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                id="father" type="Father's / Husband Name" placeholder="अपना नाम दर्ज करें  " v-model="NameInHindi"
                required />
            </div>
            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" :for="father">पिता / पति का नाम
                </label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="father" type="Father's / Husband Name" placeholder="पिता / पति का नाम" v-model="FatherNameInHindi"
                  required />
              </div>

              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode">मकान संख्या / वार्ड संख्या</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode" type="text" placeholder="अपना मकान संख्या / वार्ड संख्या दर्ज करें" v-model="WardInHIndi"
                  required />
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode">गली / मोहल्ला / गांव</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode" type="text" placeholder="अपनी गली / मोहल्ला / गांव दर्ज करें" v-model="HouseInHIndi"
                  required />
              </div>
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode">डाकघर</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode" type="text" placeholder="डाकघर" v-model="PostOfficeInHIndi" required />
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode">जिला</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode" type="text" placeholder="अपना जिला दर्ज करें" v-model="DistHIndi" required />
              </div>
              <div class="mb-4">
                <label class="flex text-gray-700 font-bold mb-2" for="pincode">राज्य</label>
                <input
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="pincode" type="text" placeholder="अपना राज्य दर्ज करें" v-model="StateInHIndi" required />
              </div>
            </div>


            <div class="flex justify-between">
              <button type="button" class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="currentPage = 2">
                Previous
              </button>
              <button type="submit" class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="printPdf()" v-if="isWaterMakeRemoved || isPremium">
                Generate PDF
              </button>

              <button type="submit" class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                @click="removeWaterMark()" v-else>
                Pay Coins
              </button>
            </div>
            <div class="flex flex-col items-center"></div>
          </div>
        </form>
      </div>
    </div>

    <!-- Dialog box -->
    <modal v-if="isDialogOpen" @close="closeDialog">
      <div class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-8 border-2 border-primary">
          <div class="flex justify-start">
            <div class="mx-4" v-if="Photo">
              <img :src="Photo" alt="Image" class="DestImageWidth" ref="image" @load="EditImage" />
            </div>

            <div v-if="Photo">
              <img :src="PhotoDestination" alt="Image" class="DestImageWidth" />
            </div>
          </div>
          <div class="justify-center mt-4">
            <button v-if="Photo" @click="saveImage" class="bg-primary text-white font-bold py-2 px-4 rounded">
              OK
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>


<script>
import QRCode from "qrcode";
import Cropper from "cropperjs";
import Header from "./Header.vue";
import AlternativeHeader from './AlternativeHeader.vue';
import { mapActions, mapState } from 'vuex';


export default {


  mounted() {
    this.getCurrentDate();
    this.retrieveData();

    if (this.isPremium) {
      this.AadharImg = require("../assets/trythree.png")
    } else {
      this.AadharImg = require("../assets/trythree_water_mark.png");
    }
  },


  methods: {

    ...mapActions(['deductCoins']),


    saveData() {
      localStorage.setItem("name", this.name);
      localStorage.setItem("Photo", this.Photo);
      localStorage.setItem("adhar", this.adhar);
      localStorage.setItem("dob", this.dob);
      localStorage.setItem("fatherName", this.fatherName);
      localStorage.setItem("gender", this.gender);
      localStorage.setItem("so", this.so);
      localStorage.setItem("street", this.street);
      localStorage.setItem("houseNo", this.houseNo);
      localStorage.setItem("postOffice", this.postOffice);
      localStorage.setItem("district", this.district);
      localStorage.setItem("state", this.state);
      localStorage.setItem("pincode", this.pincode);

      localStorage.setItem("soInHindi", this.soInHindi);
      localStorage.setItem("WardInHIndi", this.WardInHIndi);
      localStorage.setItem("NameInHindi", this.NameInHindi);
      localStorage.setItem("FatherNameInHindi", this.FatherNameInHindi);
      localStorage.setItem("HouseInHIndi", this.HouseInHIndi);
      localStorage.setItem("PostOfficeInHIndi", this.PostOfficeInHIndi);
      localStorage.setItem("DistHIndi", this.DistHIndi);
      localStorage.setItem("StateInHIndi", this.StateInHIndi);
      // You can use sessionStorage instead if you want the data to be available only for the current session
    },
    retrieveData() {
      this.name = localStorage.getItem("name") || "";
      this.adhar = localStorage.getItem("adhar") || "";
      this.dob = localStorage.getItem("dob") || "";
      this.fatherName = localStorage.getItem("fatherName") || "";
      this.gender = localStorage.getItem("gender") || "";
      this.so = localStorage.getItem("so") || "";
      this.street = localStorage.getItem("street") || "";
      this.houseNo = localStorage.getItem("houseNo") || "";
      this.postOffice = localStorage.getItem("postOffice") || "";
      this.district = localStorage.getItem("district") || "";
      this.state = localStorage.getItem("state") || "";
      this.pincode = localStorage.getItem("pincode") || "";


      this.soInHindi = localStorage.getItem("soInHindi") || "";
      this.WardInHIndi = localStorage.getItem("WardInHIndi") || "";
      this.NameInHindi = localStorage.getItem("NameInHindi") || "";
      this.FatherNameInHindi = localStorage.getItem("FatherNameInHindi") || "";
      this.HouseInHIndi = localStorage.getItem("HouseInHIndi") || "";
      this.PostOfficeInHIndi = localStorage.getItem("PostOfficeInHIndi") || "";
      this.DistHIndi = localStorage.getItem("DistHIndi") || "";
      this.StateInHIndi = localStorage.getItem("StateInHIndi") || "";



    },

    getCurrentDate() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0") + "/";
      const month = String(date.getMonth() + 1).padStart(2, "0") + "/";
      const year = date.getFullYear();
      this.currentDate = day + month + year;
    },

    async removeWaterMark() {

      const deductionResult = await this.deductCoins({ amount: 10 });
      if (deductionResult) {
        this.isWaterMakeRemoved = true
        this.AadharImg = require("../assets/trythree.png")
      } else {
        alert("Insuffricient coins");
      }
    },


    async printPdf() {
      this.saveData();

      await this.QrCodeGenerate();

      this.$nextTick(() => {
        const route = this.$router.resolve({ path: '/practice' }).route;

        // Check if meta object exists
        if (route && route.meta) {
          // Set meta header value to false
          route.meta.hideHeader = false;
        }

        if (typeof window.print === "function") {
          window.print();
        } else {
          alert("Please use another browser.");
        }
      });
    },


    openDialog(e) {
      // Set the initial image URL for display in the dialog
      this.dialogImageUrl = e;
      // Open the dialog
      this.isDialogOpen = true;
    },
    saveImage() {
      // Save the edited image data
      // Assign dialogImageUrl to imageData
      this.imageData = this.dialogImageUrl;
      this.Photo = null;
      // Close the dialog
      this.isDialogOpen = false;
    },
    handleImageUpload(event) {

      if (!this.isPremium) {
        this.AadharImg = require("../assets/trythree_water_mark.png")
      }

      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.Photo = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.openDialog(this.Photo);
      }
    },
    EditImage() {
      this.image = this.$refs.image;
      this.cropper = new Cropper(this.image, {
        zoomable: true,
        scalable: false,
        aspectRatio: 1.6 / 2,
        rotatable: true,
        crop: () => {
          const canvas = this.cropper.getCroppedCanvas();
          if (canvas.width === 0 || canvas.height === 0) {
            console.error("Invalid canvas dimensions");
            return;
          }
          // Assign the cropped image to the PhotoDestination variable
          this.PhotoDestination = canvas.toDataURL("image/jpeg");
        },
      });
    },

    formatAdhar() {
      let value = this.adhar.replace(/\s/g, ""); // remove spaces
      value = value.replace(/(\d{4})/g, "$1 "); // add spaces after every 4 digits
      this.adhar = value.trim(); // trim the value and update v-model
    },
    async QrCodeGenerate() {

      if(this.gender == "पुरुष / MALE"){
        this.genderForQrCode = "M"
      }else{
        this.genderForQrCode = "F"
      }

      const certificateInfo = ` <?xml version ="1.0" encoding="UTF-8"?><PrintLetterBarcodeData uid="${this.adhar}" Name= ${this.name} gender="${this.genderForQrCode}" DOB= ${this.dob} co = ${this.so + " " + this.fatherName + " Ioc = " + this.houseNo + " " + this.street + " " + this.postOffice + " dist= " + this.district
        + " state = " + this.state + " pc = " + this.pincode}/>`;
      const qrCodeImageUrl = await QRCode.toDataURL(certificateInfo, {
        scale: 4,
      });
      this.QrImageData = qrCodeImageUrl;

    },
  },

  data() {
    return {
      currentPage: 1,
      currentDate: null,
      name: "",
      Photo: "",
      adhar: "",
      dob: "",
      fatherName: "",
      gender: "",
      genderForQrCode:"F",
      adharNumberIssueDate:"",
      detalsAsOn:"",
      so: "",
      houseNo: "",
      street: "",
      postOffice: "",
      district: "",
      state: "",
      pincode: "",
      // in HIndi
      soInHindi: "पुत्र",
      WardInHIndi: "",
      NameInHindi: "",
      FatherNameInHindi: "",
      HouseInHIndi: "",
      PostOfficeInHIndi: "",
      DistHIndi: "",
      StateInHIndi: "",
      /// qr code
      QrImageData: "",
      isDialogOpen: false,
      dialogImageUrl: "",
      PhotoDestination: "",
      AadharImg: require("../assets/trythree_water_mark.png"),
      isWaterMakeRemoved: false,


    };
  },
  computed: {
    ...mapState(['isPremium']),
    formattedDOB() {
      if (!this.dob) {
        return "";
      }
      const date = new Date(this.dob);
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      const day = `${date.getDate()}`.padStart(2, "0");
      return `${day}/${month}/${year}`;
    },
    formattedIssueDate() {
      if (!this.adharNumberIssueDate) {
        return "";
      }
      const date = new Date(this.adharNumberIssueDate);
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      const day = `${date.getDate()}`.padStart(2, "0");
      return `${day}/${month}/${year}`;
    },
    formattedDetailsAsOn() {
      if (!this.detalsAsOn) {
        return "";
      }
      const date = new Date(this.detalsAsOn);
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      const day = `${date.getDate()}`.padStart(2, "0");
      return `${day}/${month}/${year}`;
    },
  },
  components: { Header, AlternativeHeader },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}

.main_div {
  width: 850px;
  height: 250px;
}

.AdharNumber {
  font-size: 17px;
  font-weight: bolder;
  letter-spacing: 1.5px;
  color: #000;
}

span {
  color: red;

}

.meraAadhaar {
  word-spacing: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #000;

}

.CommonTextFontForHeading {
  font-size: 11px;
  color: #000000;
  margin-top: 6px;
}

.qrCodeImageView {
  left: 243px;
}



.CommonTextFont {
  font-size: 12px;
  color: #000000;
}

.CommonTextFontD {
  font-size: 12px;
  color: #000000;
}

.CommonTextFontDAlone {
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0rcap;
  word-spacing: 0rem;
  color: #000000;
  margin-inline-start: -105px;
  margin-top: 148px;
}


.DetailsAsOfNowFontDAlone {
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0rcap;
  word-spacing: 0rem;
  color: #000000;
  margin-inline-start: -7px;
  margin-top: 140px;
}


@media print {
  @page {
    margin-inline-end: 35mm;
    margin-top: 5mm;

  }

  body * {
    visibility: hidden;
  }

  .AdCanvas,
  .AdCanvas * {
    visibility: visible;

  }

  .AdCanvas {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.destinationPhotoWidth {
  width: 90px;
  height: 110px;
  top: 72px;
}

.DestImageWidth {
  width: 400px;
  height: 500px;

}
</style>
