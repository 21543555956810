<template>
    <div class="h-screen flex justify-center items-center">
        <button @click="signInWithGoogle" class="bg-primary text-white px-4 py-2 rounded hover:bg-secondary">Sign up
            with Google</button>
    </div>
</template>

<script setup>
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useRouter } from 'vue-router';


const auth = getAuth();

const router = useRouter();


const signInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        router.push({ name: 'BuyCoins' });
    } catch (error) {
        console.error(error.message);
        // Handle error
    }
};
</script>

<style scoped>
button {

    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
</style>