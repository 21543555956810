<template>
  <div class="bg-gray-100 min-h-screen mt-16 py-10 px-4 sm:px-6 lg:px-8">
    <div class="max-w-3xl mx-auto">
      <h1 class="text-3xl font-bold mb-6">CSP Photo & Aadhar Print</h1>

      <!-- Aadhar Card Printing Section -->
      <section class="mb-10">
        <h2 class="text-xl font-semibold mb-4">Aadhar Card Printing</h2>
        <p class="mb-2">
          We offer manual Aadhar card printing services, allowing you to print
          high-quality Aadhar cards on demand. Simply provide us with the Aadhar
          card details and the desired format, and we'll print it for you.
        </p>
        <p class="mb-2">
          Our printing process ensures accurate colors and sharp details,
          resulting in professional-looking Aadhar cards that meet the required
          standards.
        </p>
        <p class="mb-2">
          Whether you need a single-sided or double-sided Aadhar card, we have
          you covered. Our advanced printing technology ensures that the
          information and images on the Aadhar card are clear and legible.
        </p>
      </section>

      <!-- PAN Card Printing Section -->
      <section class="mb-10">
        <h2 class="text-xl font-semibold mb-4">PAN Card Printing</h2>
        <p class="mb-2">
          Print your PAN card with ease using our PAN card printing service. We
          understand the importance of accurate and well-printed PAN cards, and
          we strive to deliver exceptional quality.
        </p>
        <p class="mb-2">
          Simply provide us with the PAN card details, including the PAN number
          and your photo, and we'll print it on a PAN card format that meets the
          required specifications.
        </p>
        <p class="mb-2">
          Our printing experts ensure that the text and images on the PAN card
          are clear and legible, resulting in a professional-looking card that
          can be used for various official purposes.
        </p>
      </section>

      <!-- Voter ID Card Printing Section -->
      <section class="mb-10">
        <h2 class="text-xl font-semibold mb-4">Voter ID Card Printing</h2>
        <p class="mb-2">
          We provide voter ID card printing services for individuals who need a
          physical copy of their voter ID card. Our printing process ensures
          that the card contains accurate information and clear images.
        </p>
        <p class="mb-2">
          Whether you need a single-sided or double-sided voter ID card, we can
          print it for you. Simply provide us with the necessary details, such
          as your voter ID number and your photo, and we'll take care of the
          rest.
        </p>
        <p class="mb-2">
          Our goal is to provide you with a voter ID card that meets the
          required standards and can be used for identification and voting
          purposes.
        </p>
      </section>

      <!-- Passport Size Photo Printing Section -->
      <section class="mb-10">
        <h2 class="text-xl font-semibold mb-4">Passport Size Photo Printing</h2>
        <p class="mb-2">
          Need high-quality passport size photos? Look no further! We offer
          passport size photo printing services that meet the specific
          requirements of different countries and official documents.
        </p>
        <p class="mb-2">
          Our advanced printing technology ensures accurate colors, proper
          sizing, and sharp details for your passport size photos. Simply
          provide us with your photo, and we'll print it in the required
          dimensions and format.
        </p>
        <p class="mb-2">
          Whether you need passport size photos for passports, visas, or other
          identification purposes, our printing service guarantees excellent
          quality and adherence to official guidelines.
        </p>
      </section>
      <!-- Passbook Printing Section -->
      <section class="mb-10">
        <h2 class="text-xl font-semibold mb-4">Passbook Printing</h2>
        <p class="mb-2">
          In addition to document printing, we offer passbook printing services
          for your banking needs. If you require a physical copy of your
          passbook, our printing solution can provide a clear and accurate
          replication of your account transactions.
        </p>
        <p class="mb-2">
          Simply provide us with your passbook details, and we'll print the
          necessary information, including account balance, transactions, and
          account holder details. Our printing process ensures that the printed
          passbook is precise and legible.
        </p>
        <p class="mb-2">
          With our passbook printing service, you can conveniently keep track of
          your financial activities in a tangible and reliable format.
        </p>
      </section>

      <!-- Aadhar Card Front and Back Printing Section -->
      <section class="mb-10">
        <h2 class="text-xl font-semibold mb-4">
          Aadhar Card Front and Back Printing
        </h2>
        <p class="mb-2">
          If you require both the front and back sides of your Aadhar card to be
          printed on a single page, our service can accommodate your needs. We
          understand the importance of having a compact and easily accessible
          representation of your Aadhar card information.
        </p>
        <p class="mb-2">
          Simply provide us with your Aadhar card details, and we'll print both
          sides on a single sheet, ensuring that all information, including the
          photo, is accurately represented.
        </p>
        <p class="mb-2">
          Our printing process maintains the readability and quality of the
          Aadhar card, providing you with a convenient solution for carrying and
          presenting your Aadhar card information.
        </p>
      </section>

      <p class="text-gray-600">
        Contact us today to learn more about our photo printing services and how
        we can assist you with your document printing needs.
      </p>
      <p class="text-gray-600">
        Note: All printed documents comply with the applicable guidelines and
        regulations to ensure their authenticity and validity.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  // Add your component logic here
};
</script>
<style>
/* Add your component styles here */
</style>