<template>
  <div class="rounded-lg shadow-md overflow-hidden hover:shadow-xl">
    <div class="flex items-center justify-center">
      <img
        :src="thumbnail"
        :alt="name"
        class="w-24 items-center object-cover"
      />
    </div>

    <div class="p-4">
      <h2 class="text-lg font-bold mb-2">{{ name }}</h2>
      <p class="text-gray-600">{{ description }}</p>
      <div class="mt-4">
        <button
          class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
          @click="redirectToOrderPurchase"
        >
          Print Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
      required: true,
    },
  },
  methods: {
    redirectToOrderPurchase() {
      if (this.id == "1") {
        this.$router.push({ name: "PassportSizePhoto" });
      }
      else if(this.id =='2'){
        this.$router.push({ name: "AdharCardPrintOnOnePage" });
      }
      else if(this.id =='3'){
        this.$router.push({ name: "PassBook" });
      }
      else if(this.id == '4'){
        this.$router.push({name:"ManualAdharPrint"})
      }
      else if(this.id == '5'){
        this.$router.push({name:"ManualVoterCardPrint"})
      }
      else if(this.id == '6'){
        this.$router.push({name:"PanCardPrint"})
      }
 
    },
  },
};
</script>


