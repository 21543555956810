<template>
  <div class="min-h-screen mt-16 flex items-center justify-center bg-gray-100">
    <div class="max-w-lg w-full bg-white p-8 rounded-lg shadow-md text-center">
      <h1 class="text-3xl font-bold mb-4">Payment Successful!</h1>
      <p class="text-gray-700 mb-6">Thank you for your purchase. Your subscription has been activated.</p>
      <p class="text-gray-700 mb-6">Close this and open the mobile application / इसको बंद करके मोबाइल ऐप खोलें</p>
      <button @click="closeApplication"
        class="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-full">
        Close Application
      </button>

      <div class="relative mt-8 p-4 border border-gray-300 rounded-lg">

        <img src="../assets/dl_poster.png" class="w-full h-auto rounded-lg border" alt="Poster Image" />

        <h2 class="text-xl font-semibold mb-2">Check Out Our New Feature!</h2>
        <p class="text-gray-700 mb-4">We have introduced a new line contact feature in our DL Printing app that makes it
          easier to manage your documents.</p>
        <button class="bg-primary text-white font-semibold py-2 px-4 rounded shadow hover:bg-secondary">
          Download
        </button>
        <p class="text-gray-700 mb-4">For any inquiries or support, feel free to reach out to us!</p>
        <p class="text-gray-700 mb-4">WhatsApp Only 7488150245</p>
      </div>



    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from 'axios';


export default {
  data() {
    return {
      amount: localStorage.getItem("amount") || "65",
      userId: localStorage.getItem("userId")
    };
  },

  async mounted() {
    this.$store.dispatch('setUid', this.userId);
    await this.fetchCurrentTimestamp()
    this.checkPaymentStatus();
  },

  computed: {
    ...mapState(['coins', 'isPremium', 'currentTimestamp']),
  },

  methods: {
    ...mapActions(['deductCoins', 'addCoins', 'addPremium', 'fetchCurrentTimestamp']),

    checkPaymentStatus() {
      const urlParams = new URLSearchParams(window.location.search);
      const paymentStatus = urlParams.get('paymentStatus');
      const transactionIdFromServer = urlParams.get('transactionId');
      const randomVerificationIdFromServer = urlParams.get('randomVerificationId');
      const transactionId = localStorage.getItem("transactionId");
      const randomVerificationId = localStorage.getItem("randomVerificationId");

      // Verify if the payment status is successful and the transaction ID matches
      if (paymentStatus === 'success' && transactionId === transactionIdFromServer && randomVerificationId === randomVerificationIdFromServer) {

        switch (this.amount) {
          case "200":
            this.addCoins(200)
            break;
          case "85":
            this.addPremium(7)
            break;
          case "120":
            this.addPremium(15)
            break;
          case "150":
            this.addPremium(30)
            break;
          case "370":
            this.addPremium(90)
            break;
          case "650":
            this.addPremium(180)
            break;
          case "1100":
            this.addPremium(360)
            break;
          case "89":
            this.updateToDLPremium(7)
            break;
          case "599":
            this.updateToDLPremium(180)
            break;
          case "899":
            this.updateToDLPremium(360)
            break;
          default:
            this.addPremium(7)
            break;
        }
        // Store a new unique transaction ID in localStorage to prevent duplicate activations
        localStorage.setItem("transactionId", Date.now().toString() + "chu");
        localStorage.setItem("randomVerificationId", Date.now().toString() + "ghanta");
      } else {
        localStorage.setItem("transactionId", Date.now().toString() + "chu");
        localStorage.setItem("randomVerificationId", Date.now().toString() + "ghanta");
        this.$router.push('/FailedPayment');
      }
    },

    async updateToDLPremium(days) {
      const userId = localStorage.getItem("userIdDL");
      const currentTimeMillis = Date.now();
      const futureTimestamp = currentTimeMillis + days * 24 * 60 * 60 * 1000; // Convert days to milliseconds
      const databaseUrl = `https://manual-dob-print-default-rtdb.firebaseio.com/Premium User/${userId}.json`;
      try {
        // Send the future timestamp to Firebase using Axios
        await axios.put(databaseUrl, futureTimestamp);

      } catch (error) {
        this.$router.push('/FailedPayment');
      }

    },

    async addPremium(days) {
      try {
        await this.$store.dispatch('addPremium', { days });

      } catch (error) {
        console.error('Error activating premium:', error);
        this.$router.push('/FailedPayment');
      }
    },
    async addCoins(coinsToAdd) {
      try {
        await this.$store.dispatch('addCoins', { coinsToAdd });

      } catch (error) {
        console.error('Error adding coins:', error);
        this.$router.push('/FailedPayment');
      }
    },


    closeApplication() {
      window.close(); // Attempt to close the current window/tab
      // You can display a message if the window can't be closed by the browser:
      if (!window.closed) {
        alert("You can close this tab or window manually.");
      }
    }
  }
};
</script>

<style scoped></style>
