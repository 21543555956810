<template>
  <div class="mt-10 flex justify-center items-center h-screen">
    <div
      class="flex items-center max-w-fit shadow-sm h-fit border-2 border-primary"
    >
      <div class="w-1/2 justify-center shadow-sm mb-4 py-4 px-4">
        <div class="">
          <div>
            <label for="frontImage" class="flex">Front Image:</label>
            <input
              type="file"
              id="frontImage"
              @change="handleFrontImageUpload"
              accept="image/*"
            />
            <div v-if="frontImageDestination" class="mt-4">
              <img
                :src="frontImageDestination"
                alt="Front Image Preview"
                class="max-w-xs max-h-xs w-52 h-48 aadhar-card-image"
              />
            </div>
          </div>

          <div>
            <label for="backImage" class="flex">Back Image:</label>
            <input
              type="file"
              id="backImage"
              @change="handleBackImageUpload"
              accept="image/*"
            />
            <div v-if="backImageDestination" class="mt-4">
              <img
                :src="backImageDestination"
                alt="Back Image Preview"
                class="max-w-xs max-h-xs aadhar-card-image"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-full">
        <div class="h-fit w-full mb-2 mx-2 py-2 px-6">
          <h2 class="text-xl font-bold mb-2 w-full">PDF Preview</h2>
          <div class="bg-white border border-gray-300 p-4 rounded-md">
            <object
              :data="pdfDataUrl"
              type="application/pdf"
              width="100%"
              height="560px"
            >
              <p>Preview is not available.</p>
            </object>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog box -->
    <modal v-if="isDialogOpen" @close="closeDialog">
      <div class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-8 border-2 border-primary">
          <div class="flex justify-start">
            <div class="mx-4" v-if="frontImagePreview">
              <img
                :src="frontImagePreview"
                alt="Image"
                class="DestImageWidth"
                ref="image"
                @load="EditImage(1)"
              />
            </div>
            <div class="mx-4" v-if="backImagePreview">
              <img
                :src="backImagePreview"
                alt="Image"
                class="DestImageWidth"
                ref="image"
                @load="EditImage(2)"
              />
            </div>
            <div v-if="frontImagePreview">
              <img
                :src="frontImageDestination"
                alt="Image"
                class="DestImageWidth"
              />
            </div>
            <div v-if="backImagePreview">
              <img
                :src="backImageDestination"
                alt="Image"
                class="DestImageWidthForSig"
              />
            </div>
          </div>
          <div class="justify-center mt-4">
            <button
              v-if="frontImagePreview"
              @click="saveImage()"
              class="bg-primary text-white font-bold py-2 px-4 rounded"
            >
              OK
            </button>
            <button
              v-if="backImagePreview"
              @click="saveImage(1)"
              class="bg-primary text-white font-bold py-2 px-4 rounded"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>


<script>
import jsPDF from "jspdf";
import Cropper from "cropperjs";

export default {
  data() {
    return {
      pdfDataUrl: "",
      pdfBlob: null,
      pdfDownloadUrl: "",
      isDialogOpen: false,
      dialogImageUrl: "",

      frontImagePreview: null,
      backImagePreview: null,

      frontImageDestination: require("../assets/defaultimage.png"),
      backImageDestination: require("../assets/defaultimage.png"),
    };
  },
  methods: {
    openDialog(e) {
      // Set the initial image URL for display in the dialog
      this.dialogImageUrl = e;

      // Open the dialog
      this.isDialogOpen = true;
    },
    saveImage(back) {
      // Save the edited image data
      // Assign dialogImageUrl to imageData
      this.imageData = this.dialogImageUrl;

      // Close the dialog
      this.isDialogOpen = false;

      if (back == 1) {
        this.generatePDF();
      }

      this.frontImagePreview = null;
      this.backImagePreview = null;
    },
    generatePDF() {
      const PanPrint = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      const Width = 85;
      const Height = 60;

      PanPrint.addImage(
        this.frontImageDestination,
        ["JPEG", "PNG"],
        10,
        10,
        Width,
        Height
      );
      PanPrint.addImage(
        this.backImageDestination,
        ["JPEG", "PNG"],
        110,
        10,
        Width,
        Height
      );

      this.savePDF(PanPrint);
    },
    savePDF(url) {
      this.pdfDataUrl = url.output("datauristring");
      this.pdfBlob = url.output("blob");
      this.pdfDownloadUrl = URL.createObjectURL(this.pdfBlob);
    },

    EditImage(side) {
      this.image = this.$refs.image;
      this.cropper = new Cropper(this.image, {
        zoomable: true,
        scalable: false,

        crop: () => {
          const canvas = this.cropper.getCroppedCanvas();

          // Check if the canvas has valid dimensions
          if (canvas.width === 0 || canvas.height === 0) {
            console.error("Invalid canvas dimensions");
            return;
          }

          // Create a new canvas with border
          const borderedCanvas = document.createElement("canvas");
          const ctx = borderedCanvas.getContext("2d");

          // Set border size and color
          const borderSize = 1; // in pixels
          const borderColor = "black";

          // Calculate new canvas size with border
          borderedCanvas.width = canvas.width + borderSize * 2;
          borderedCanvas.height = canvas.height + borderSize * 2;

          // Draw border
          ctx.fillStyle = borderColor;
          ctx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);

          // Draw cropped image
          ctx.drawImage(canvas, borderSize, borderSize);
          if (side == 1) {
            this.frontImageDestination = borderedCanvas.toDataURL("image/jpeg");
          } else {
            this.backImageDestination = borderedCanvas.toDataURL("image/jpeg");
          }
        },
      });
    },
    handleFrontImageUpload(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.frontImagePreview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.openDialog(this.frontImagePreview);
      }
    },
    handleBackImageUpload(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.backImagePreview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.openDialog(this.backImagePreview);
      }
    },
  },
};
</script>
<style scoped>
.aadhar-card-image {
  width: 15vw;
  height: 12vw;
}
.DestImageWidth {
  width: 400px;
  height: 300px;
}
.DestImageWidthForSig {
  width: 400px;
  height: 300px;
}
</style>
