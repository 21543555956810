<template>
    <div class="min-h-screen h-fit bg-gray-100 py-8 mt-20">
      <div class="max-w-3xl mx-auto px-4">
        <h1 class="text-4xl font-bold text-center mb-8">Privacy Policy</h1>
        <p class="text-gray-700 mb-4">
          Last updated on May 1st 2024
        </p>
        <div class="bg-white rounded-lg shadow-md p-6">
          <p class="text-gray-700 mb-4">
            This privacy policy sets out how CSP Photo & ManualAadhar Print uses and protects any information that you give CSP Photo & ManualAadhar Print when you visit their website and/or agree to purchase from them.
          </p>
          <p class="text-gray-700 mb-4">
            CSP Photo & ManualAadhar Print is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, you can be assured that it will only be used in accordance with this privacy statement.
          </p>
          <p class="text-gray-700 mb-4">
            CSP Photo & ManualAadhar Print may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you adhere to these changes.
          </p>
          
          <h2 class="text-xl font-bold mb-2">Information We Collect</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
            <li>Name</li>
            <li>Contact information including email address</li>
            <li>Demographic information such as postcode, preferences, and interests, if required</li>
            <li>Other information relevant to customer surveys and/or offers</li>
          </ul>
          
          <h2 class="text-xl font-bold mb-2">What We Do With The Information We Gather</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
            <li>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</li>
            <li>Internal record keeping.</li>
            <li>We may use the information to improve our products and services.</li>
            <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
            <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customize the website according to your interests.</li>
          </ul>
          
          <h2 class="text-xl font-bold mb-2">How We Use Cookies</h2>
          <p class="text-gray-700 mb-4">
            A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes, and dislikes by gathering and remembering information about your preferences.
          </p>
          <p class="text-gray-700 mb-4">
            We use traffic log cookies to identify which pages are being used. This helps us analyze data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
          </p>
          <p class="text-gray-700 mb-4">
            Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
          </p>
          <p class="text-gray-700 mb-4">
            You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
          </p>
          
          <h2 class="text-xl font-bold mb-2">Controlling Your Personal Information</h2>
          <p class="text-gray-700 mb-4">
            You may choose to restrict the collection or use of your personal information in the following ways:
          </p>
          <ul class="list-disc list-inside text-gray-700 mb-4">
            <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes.</li>
            <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us.</li>
          </ul>
          <p class="text-gray-700 mb-4">
            We will not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
          </p>
          <p class="text-gray-700 mb-4">
            If you believe that any information we are holding on you is incorrect or incomplete, please write to Bhaluaha kanhauli sonbarsa sitamarhi bihar sitamarhi BIHAR 843317 or contact us at [your email address] as soon as possible. We will promptly correct any information found to be incorrect.
          </p>
          
          <p class="text-gray-700">
            Disclaimer: The above content is created at Rajnish Kumar's sole discretion. CSP Photo & ManualAadhar Print shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    // No data or methods are necessary for this static component
  };
  </script>
  
  <style scoped>
  /* Add any scoped styles here */
  </style>
  