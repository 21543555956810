<template>
    <div class="profile h-screen flex justify-center items-center">
        <div class="profile-info text-center">
            <div v-if="loading">
                <p>Loading...</p>
            </div>
            <div v-else>
                <div>
                    <img :src="userPhotoUrl" :alt="'loading'" class="profile-photo border-2 mx-auto" />
                </div>
                <div>
                    <p><strong>Email:</strong> {{ userEmail }}</p>
                    <p><strong>UID:</strong> {{ userUid }}</p>
                    <!-- Add other profile information here -->
                </div>
                <div class="mt-4">
                    <button @click="shareUid"
                        class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded mr-2">
                        Share UID
                    </button>
                    <button @click="logout" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Logout
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { auth } from './firebase';
import router from '@/routes'; // Assuming your router file is named 'routes.js'
import store from './store';

export default {
    name: 'UserProfile',

    data() {
        return {
            loading: true,
            userEmail: "",
            userPhotoUrl: "",
            userUid: ""
        }
    },
    async created() {
        await this.getCurrentUserData();
        this.loading = false;
    },
    methods: {
        async getCurrentUserData() {
            this.userEmail = auth.currentUser.email;
            this.userPhotoUrl = auth.currentUser.photoURL || 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/Default-welcomer.png/800px-Default-welcomer.png';
            this.userUid = auth.currentUser.uid;
        },
        shareUid() {
            // Phone number to share the message with
            const phoneNumber = '7488150245';

            // Message to share
            const message = `${this.userUid}`;

            // URL for opening WhatsApp with the message and phone number
            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

            // Open WhatsApp with the specified phone number and message
            window.open(whatsappUrl, '_blank');
        },

        logout() {

            auth.signOut().then(() => {
                store.dispatch('resetUserData');
                router.push('/login');

            }).catch(error => {
                // Handle any errors that occur during logout
                console.error('Logout error:', error);
            });
        },
    }
};
</script>

<style scoped>
.profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
</style>