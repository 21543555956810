<template>
  <header class="flex items-center p-4 bg-primary text-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
    <router-link class="flex items-center" :to="previousRoute">
     <img src="../assets/arrow-left.png" alt="Logo" class="w-8 h-8 mr-2 mx-10" style="filter: invert(1);"> <!-- Add inline style to set arrow color to white -->
     <img :src = "coinImage" alt="Logo" class="w-8 h-8 mr-2 mx-10"> 
      <span class="text-lg font-bold" v-if="isPremium">Access Premium Perc</span>
      <span class="text-lg font-bold" v-else>Coins (as of now) {{  coins }}</span>
    </router-link>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-red-900">
   
    </div>
  </header>
</template>

<script>

import { mapState } from 'vuex';

export default {

  mounted(){
    if(this.isPremium){
      this.coinImage = require('../assets/diamond.png')
    }

  },

  data() {
    return {
      appName: "Csp Photo & Aadhar Print",
      previousRoute: "/", // Set the appropriate previous route path
      coinImage:require('../assets/coins.png'),
     

    };
  },
  computed: {
    ...mapState(['coins','isPremium'])
  },

};
</script>

