<template>
  <div class="bg-gray-100 h-screen py-10 mt-16">
    <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 class="text-4xl font-bold mb-4">About Us</h1>
      <p class="text-gray-700 mb-8">
        csp photo print is a website that allows you to print Aadhaar Card, Pan Card, Voter ID Card, etc. for demo and educational purpose. We charge a nominal fee for it to maintain our servers. This website is not a part of The Indian Government.
      </p>


      <h2 class="text-2xl font-bold mb-4">Our Mission</h2>
      <p class="text-gray-700 mb-8">
        At CSP Photo Print, our mission is to provide convenient and reliable
        printing services for essential identification documents. We strive to
        meet the diverse needs of our customers by offering accurate
        reproductions and timely delivery, all while maintaining a focus on
        quality and customer satisfaction.
      </p>


    </div>
  </div>
</template>



<script>
export default {
  // Add your component logic here
};
</script>

<style>
/* Add your component styles here */
</style>
