<template>
  <div
    class="bg-gray-100 min-h-screen flex flex-col justify-center items-center"
  >
    <div class="max-w-md w-full mx-auto p-8 bg-white rounded shadow">
      <h2 class="text-2xl font-bold mb-6">Contact Us</h2>
      <form @submit.prevent="submitForm">
        <div class="mb-4">
          <label for="name" class="flex mb-2">Name</label>
          <input
            type="text"
            id="name"
            v-model="name"
            class="w-full border border-gray-300 px-4 py-2 rounded"
            required
          />
        </div>
        <div class="mb-4">
          <label for="email" class="flex mb-2">Email / Mob no</label>
          <input
            type="email"
            id="email"
            v-model="email"
            class="w-full border border-gray-300 px-4 py-2 rounded"
            required
          />
        </div>
        <div class="mb-4">
          <label for="message" class="flex mb-2">Message</label>
          <textarea
            id="message"
            v-model="message"
            class="w-full border border-gray-300 px-4 py-2 rounded"
            required
          ></textarea>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
    submitForm() {
      // Perform form submission logic here
      // You can access the form data using `this.name`, `this.email`, `this.message`
      console.log("Form submitted");
    },
  },
};
</script>

<style>
/* Add your custom styles here if needed */
</style>
